import { Form, Formik } from "formik";
import { FC, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Button } from "../../styles/button";
import { ApiError, FormGroup } from "../../styles/form";
import CustomModal from "../common/modal/CustomModal";
import * as Yup from "yup";
import validations from "../../utils/validations";
import ModuleDocumentAddContent, {
  IFormData,
} from "./ModuleDocumentAddContent";
import { TemplateDocumentType } from "../../models/template";
import { connect } from "react-redux";
import { templateDocumentListLoad } from "../../store/templateDocumentList";
import templateApi from "../../api/template";
import bucketApi from "../../api/bucket";
import { promiseToastSave } from "../../utils/toasts";
import { errorSet } from "../../utils/error";

interface IProps {
  file: File | null;
  folderId: string;
  isOpen: boolean;
  close(): void;
  templateDocumentListLoad(reload: boolean): void;
}

const ModuleDocumentAddModal: FC<IProps> = ({
  file,
  folderId,
  isOpen,
  close,
  templateDocumentListLoad,
}) => {
  const { t } = useTranslation();
  const [error, setError] = useState<string | null>(null);

  useEffect(() => {
    setError(null);
  }, [isOpen]);

  const handleSubmit = async (data: IFormData) => {
    setError(null);
    try {
      await promiseToastSave(async () => {
        const originalName = file!.name;
        let fileName = data.name;

        //Keep file extension from original name.
        const lastIndex = originalName.lastIndexOf(".");
        if (lastIndex !== -1) {
          const originalExt = originalName.substring(lastIndex);

          if (!fileName.toLowerCase().endsWith(originalExt.toLowerCase())) {
            fileName = fileName + originalExt;
          }
        }

        const uploadUrl = await templateApi.getDocumentUploadUrl(
          fileName,
          folderId,
          null
        );

        await bucketApi.uploadDocument(uploadUrl.data, file!);
        await templateApi.addDocument(fileName, folderId, data.type);

        templateDocumentListLoad(true);
        close();
      });
    } catch (err) {
      errorSet(setError, err, t);
    }
  };

  return (
    <Formik
      initialValues={{
        file: "",
        name: "",
        type: TemplateDocumentType.Client,
      }}
      validationSchema={Yup.object({
        name: validations.stringRequired(t),
      })}
      validateOnMount={true}
      onSubmit={handleSubmit}
    >
      {({ isSubmitting, isValid, submitForm }) => (
        <CustomModal
          title={t("document.addDocument.title")}
          isOpen={isOpen}
          close={close}
          actions={
            <>
              <Button
                ver="secondary"
                disabled={isSubmitting}
                onClick={() => close()}
              >
                {t("common.cancel")}
              </Button>
              <Button
                disabled={!isValid || isSubmitting}
                onClick={() => submitForm()}
              >
                {t("common.save")}
              </Button>
            </>
          }
        >
          <Form>
            <FormGroup>
              <ModuleDocumentAddContent file={file} />
              {error && <ApiError>{error}</ApiError>}
            </FormGroup>
          </Form>
        </CustomModal>
      )}
    </Formik>
  );
};

const mapDispachToProps = {
  templateDocumentListLoad,
};

export default connect(null, mapDispachToProps)(ModuleDocumentAddModal);
