import { AxiosRequestConfig, AxiosResponse } from "axios";
import { IActionItem } from "../models/calendar";
import {
  ClientStateType,
  IClient,
  IClientBenefitItem,
  IClientCare,
  IClientCareItem,
  IClientContactPerson,
  IClientContactPersonItem,
  IClientDebtItem,
  IClientDocumentItem,
  IClientHouse,
  IClientItem,
  IClientNoteItem,
  IClientRevenueItem,
} from "../models/client";
import { IItemIdName } from "../models/common";
import { IFolderItem } from "../models/document";
import api from "../utils/api";

const getList = async (
  config: AxiosRequestConfig
): Promise<AxiosResponse<IClientItem[]>> => api.get(`/api/Client`, config);

const getCount = async (
  config: AxiosRequestConfig
): Promise<AxiosResponse<number>> => api.get(`/api/Client/Count`, config);

const getSuggestionList = async (
  config: AxiosRequestConfig
): Promise<AxiosResponse<IItemIdName[]>> =>
  api.get(`/api/Client/Suggested/List`, config);

const clientListExportCsv = (
  params: URLSearchParams
): Promise<AxiosResponse<Blob>> =>
  api.get(`/api/Client/Export/Csv`, { params, responseType: "blob" });

const clientListExportXlsx = (
  params: URLSearchParams
): Promise<AxiosResponse<Blob>> =>
  api.get(`/api/Client/Export/Xlsx`, { params, responseType: "blob" });

const getClient = async (clientId: number): Promise<AxiosResponse<IClient>> =>
  api.get(`/api/Client/${clientId}`);

const updateClient = async (client: IClient): Promise<AxiosResponse<IClient>> =>
  api.put(`/api/Client/${client.id}`, client);

const createClient = async (client: IClient): Promise<AxiosResponse<IClient>> =>
  api.post(`/api/Client`, client);

const changeClientState = (
  clientId: number,
  state: ClientStateType
): Promise<AxiosResponse<IClient>> =>
  api.put(`/api/Client/${clientId}/State/${state}`);

const anonymiseClient = async (
  clientId: number
): Promise<AxiosResponse<IClient>> =>
  api.put(`/api/Client/${clientId}/Anonymise`);

const exportClient = (clientId: number): Promise<AxiosResponse<Blob>> =>
  api.get(`/api/Client/${clientId}/Export`, {
    responseType: "blob",
  });

const getClientHouse = async (
  clientId: number
): Promise<AxiosResponse<IClientHouse>> =>
  api.get(`/api/Client/${clientId}/HousingSituation`);

const updateClientHouse = async (
  clientId: number,
  data: IClientHouse
): Promise<AxiosResponse<IClientHouse>> =>
  api.put(`/api/Client/${clientId}/HousingSituation`, data);

const getClientRiskList = async (
  clientId: number
): Promise<AxiosResponse<IItemIdName[]>> =>
  api.get(`/api/Client/${clientId}/Risks`);

const updateClientRiskList = async (
  clientId: number,
  data: number[]
): Promise<AxiosResponse<IItemIdName[]>> =>
  api.put(`/api/Client/${clientId}/Risks`, data);

const getClientRevenueList = async (
  clientId: number,
  config: AxiosRequestConfig
): Promise<AxiosResponse<IClientRevenueItem[]>> =>
  api.get(`/api/Client/${clientId}/Revenue`, config);

const getClientRevenueCount = async (
  clientId: number,
  config: AxiosRequestConfig
): Promise<AxiosResponse<number>> =>
  api.get(`/api/Client/${clientId}/Revenue/Count`, config);

const updateClientRevenue = async (
  revenueId: number,
  data: IClientRevenueItem
): Promise<AxiosResponse<IClientRevenueItem>> =>
  api.put(`/api/Client/Revenue/${revenueId}`, data);

const deleteClientRevenue = async (
  revenueId: number
): Promise<AxiosResponse<boolean>> =>
  api.delete(`/api/Client/Revenue/${revenueId}`);

const createClientRevenue = async (
  clientId: number,
  data: IClientRevenueItem
): Promise<AxiosResponse<IClientRevenueItem>> =>
  api.post(`/api/Client/${clientId}/Revenue`, data);

const getClientBenefitList = async (
  clientId: number,
  config: AxiosRequestConfig
): Promise<AxiosResponse<IClientBenefitItem[]>> =>
  api.get(`/api/Client/${clientId}/SocialBenefits`, config);

const getClientBenefitCount = async (
  clientId: number,
  config: AxiosRequestConfig
): Promise<AxiosResponse<number>> =>
  api.get(`/api/Client/${clientId}/SocialBenefits/Count`, config);

const updateClientBenefit = async (
  benefitId: number,
  data: IClientBenefitItem
): Promise<AxiosResponse<IClientBenefitItem>> =>
  api.put(`/api/Client/SocialBenefits/${benefitId}`, data);

const deleteClientBenefit = async (
  benefitId: number
): Promise<AxiosResponse<boolean>> =>
  api.delete(`/api/Client/SocialBenefits/${benefitId}`);

const createClientBenefit = async (
  clientId: number,
  data: IClientBenefitItem
): Promise<AxiosResponse<IClientBenefitItem>> =>
  api.post(`/api/Client/${clientId}/SocialBenefits`, data);

const getClientDebtList = async (
  clientId: number,
  config: AxiosRequestConfig
): Promise<AxiosResponse<IClientDebtItem[]>> =>
  api.get(`/api/Client/${clientId}/Debt`, config);

const getClientDebtCount = async (
  clientId: number,
  config: AxiosRequestConfig
): Promise<AxiosResponse<number>> =>
  api.get(`/api/Client/${clientId}/Debt/Count`, config);

const updateClientDebt = async (
  debtId: number,
  data: IClientDebtItem
): Promise<AxiosResponse<IClientDebtItem>> =>
  api.put(`/api/Client/Debt/${debtId}`, data);

const deleteClientDebt = async (
  debtId: number
): Promise<AxiosResponse<boolean>> => api.delete(`/api/Client/Debt/${debtId}`);

const createClientDebt = async (
  clientId: number,
  data: IClientDebtItem
): Promise<AxiosResponse<IClientDebtItem>> =>
  api.post(`/api/Client/${clientId}/Debt`, data);

const getClientNoteList = async (
  clientId: number,
  config: AxiosRequestConfig
): Promise<AxiosResponse<IClientNoteItem[]>> =>
  api.get(`/api/Client/${clientId}/Notes`, config);

const getClientNoteCount = async (
  clientId: number,
  config: AxiosRequestConfig
): Promise<AxiosResponse<number>> =>
  api.get(`/api/Client/${clientId}/Notes/Count`, config);

const updateClientNote = async (
  noteId: number,
  data: IClientNoteItem
): Promise<AxiosResponse<IClientNoteItem>> =>
  api.put(`/api/Client/Note/${noteId}`, data);

const createClientNote = async (
  clientId: number,
  data: IClientNoteItem
): Promise<AxiosResponse<IClientNoteItem>> =>
  api.post(`/api/Client/${clientId}/Note`, data);

const getClientCareList = async (
  clientId: number,
  config: AxiosRequestConfig
): Promise<AxiosResponse<IClientCareItem[]>> =>
  api.get(`/api/Client/${clientId}/Cares`, config);

const getClientCareCount = async (
  clientId: number,
  config: AxiosRequestConfig
): Promise<AxiosResponse<number>> =>
  api.get(`/api/Client/${clientId}/Cares/Count`, config);

const getClientCare = async (
  clientCareId: number
): Promise<AxiosResponse<IClientCare>> => api.get(`/api/Care/${clientCareId}`);

const updateClientCare = async (
  data: IClientCare
): Promise<AxiosResponse<IClientCare>> => api.put(`/api/Care/${data.id}`, data);

const createClientCare = async (
  data: IClientCare
): Promise<AxiosResponse<IClientCare>> => api.post(`/api/Care`, data);

const getClientFolderList = async (
  clientId: number,
  config: AxiosRequestConfig
): Promise<AxiosResponse<IFolderItem[]>> =>
  api.get(`/api/Clients/${clientId}/Documents/Folders`, config);

const getClientFolderCount = async (
  clientId: number,
  config: AxiosRequestConfig
): Promise<AxiosResponse<number>> =>
  api.get(`/api/Clients/${clientId}/Documents/Folders/Count`, config);

const getClientFolder = async (
  clientId: number,
  folderId: string | undefined
): Promise<AxiosResponse<IItemIdName[]>> =>
  api.get(`/api/Clients/${clientId}/Documents/Folder/${folderId ?? "0"}/Path`);

const createFolder = async (
  clientId: number,
  parentId: string,
  data: IFolderItem
): Promise<AxiosResponse<IFolderItem>> => {
  const params = new URLSearchParams();
  if (parentId) {
    params.append("parentId", parentId);
  }
  return api.post(`/api/Clients/${clientId}/Documents/Folders`, data, {
    params,
  });
};

const updateFolder = async (
  clientId: number,
  folderId: string | undefined,
  data: IFolderItem
): Promise<AxiosResponse<IFolderItem>> =>
  api.put(
    `/api/Clients/${clientId}/Documents/Folders/${folderId ?? "0"}`,
    data
  );

const getClientDocumentList = async (
  clientId: number,
  folderId: string | undefined,
  config: AxiosRequestConfig
): Promise<AxiosResponse<IClientDocumentItem[]>> =>
  api.get(
    `/api/Clients/${clientId}/Documents/Folder/${folderId ?? "0"}/Documents`,
    config
  );

const getClientDocumentCount = async (
  clientId: number,
  folderId: string | undefined,
  config: AxiosRequestConfig
): Promise<AxiosResponse<number>> =>
  api.get(
    `/api/Clients/${clientId}/Documents/Folder/${
      folderId ?? "0"
    }/Documents/Count`,
    config
  );

const addClientDocumentFromTemplate = async (
  clientId: number,
  folderId: string | undefined,
  templateId: number
): Promise<AxiosResponse<IClientDocumentItem>> =>
  api.post(
    `/api/Clients/${clientId}/Documents/Folder/${
      folderId ?? "0"
    }/Documents/Template/${templateId}`
  );

const getClientDocumentUploadUrl = async (
  clientId: number,
  folderId: string | undefined,
  fileName: string | null,
  documentId: string | null
): Promise<AxiosResponse<string>> => {
  const params = new URLSearchParams();

  if (folderId) {
    params.append("folderId", folderId);
  }
  if (fileName) {
    params.append("fileName", fileName);
  }
  if (documentId) {
    params.append("documentId", documentId);
  }

  return api.get(`/api/Client/${clientId}/Document/UploadUrl`, { params });
};

const addClientDocument = async (
  clientId: number,
  folderId: string | undefined,
  fileName: string
): Promise<AxiosResponse<string>> => {
  const params = new URLSearchParams();
  params.append("fileName", fileName);

  return api.post(
    `/api/Clients/${clientId}/Document/Folder/${folderId ?? "0"}/Document`,
    undefined,
    {
      params,
    }
  );
};

const getClientDocumentDownloadUrl = async (
  clientId: number,
  documentId: number
): Promise<AxiosResponse<string>> =>
  api.get(`/api/Clients/${clientId}/Document/${documentId}/Download`);

const updateClientDocument = async (
  clientId: number,
  documentId: number
): Promise<AxiosResponse<string>> =>
  api.put(`/api/Clients/${clientId}/Document/${documentId}`);

const getClientContactPersonList = async (
  clientId: number,
  config: AxiosRequestConfig
): Promise<AxiosResponse<IClientContactPersonItem[]>> =>
  api.get(`/api/Client/${clientId}/ContactPerson`, config);

const getClientContactPersonCount = async (
  clientId: number,
  config: AxiosRequestConfig
): Promise<AxiosResponse<number>> =>
  api.get(`/api/Client/${clientId}/ContactPerson/Count`, config);

const getClientContactPerson = async (
  clientContactPersonId: number
): Promise<AxiosResponse<IClientContactPerson>> =>
  api.get(`/api/Client/ContactPerson/${clientContactPersonId}`);

const updateClientContactPerson = async (
  data: IClientContactPerson
): Promise<AxiosResponse<IClientContactPerson>> =>
  api.put(`/api/Client/ContactPerson/${data.id}`, data);

const createClientContactPerson = async (
  clientId: number,
  data: IClientContactPerson
): Promise<AxiosResponse<IClientContactPerson>> =>
  api.post(`/api/Client/${clientId}/ContactPerson`, data);

const getClientActionList = async (
  clientId: number,
  config: AxiosRequestConfig
): Promise<AxiosResponse<IActionItem[]>> =>
  api.get(`/api/Client/${clientId}/Actions`, config);

const getClientActionCount = async (
  clientId: number,
  config: AxiosRequestConfig
): Promise<AxiosResponse<number>> =>
  api.get(`/api/Client/${clientId}/Actions/Count`, config);

const getClientRightGroupList = async (
  clientId: number,
  config: AxiosRequestConfig
): Promise<AxiosResponse<IItemIdName[]>> =>
  api.get(`/api/Client/${clientId}/Right/Group`, config);

const getClientRightGroupCount = async (
  clientId: number,
  config: AxiosRequestConfig
): Promise<AxiosResponse<number>> =>
  api.get(`/api/Client/${clientId}/Right/Group/Count`, config);

const createClientRightGroup = async (
  clientId: number,
  data: IItemIdName
): Promise<AxiosResponse<IItemIdName>> =>
  api.post(`/api/Client/${clientId}/Right/Group`, data);

const deleteClientRightGroup = async (
  clientId: number,
  groupId: number
): Promise<AxiosResponse<boolean>> =>
  api.delete(`/api/Client/${clientId}/Right/Group/${groupId}`);

const getClientRightUserList = async (
  clientId: number,
  config: AxiosRequestConfig
): Promise<AxiosResponse<IItemIdName[]>> =>
  api.get(`/api/Client/${clientId}/Right/User`, config);

const getClientRightUserCount = async (
  clientId: number,
  config: AxiosRequestConfig
): Promise<AxiosResponse<number>> =>
  api.get(`/api/Client/${clientId}/Right/User/Count`, config);

const createClientRightUser = async (
  clientId: number,
  data: IItemIdName
): Promise<AxiosResponse<IItemIdName>> =>
  api.post(`/api/Client/${clientId}/Right/User`, data);

const deleteClientRightUser = async (
  clientId: number,
  userId: number
): Promise<AxiosResponse<boolean>> =>
  api.delete(`/api/Client/${clientId}/Right/User/${userId}`);

const clientSendGdprAgreementCode = async (
  clientId: number
): Promise<AxiosResponse<boolean>> =>
  api.post(`/api/Client/${clientId}/GdprAgreement/Code`);

const clientAddGdprAgreement = async (
  clientId: number,
  secret?: string | null
): Promise<AxiosResponse<IClient>> => {
  const params = new URLSearchParams();

  if (secret) {
    params.append("secret", secret);
  }

  return api.post(`/api/Client/${clientId}/GdprAgreement`, undefined, {
    params,
  });
};

const clientApi = {
  getList,
  getCount,
  getSuggestionList,
  clientListExportCsv,
  clientListExportXlsx,
  getClient,
  updateClient,
  createClient,
  changeClientState,
  anonymiseClient,
  exportClient,
  getClientHouse,
  updateClientHouse,
  getClientRiskList,
  updateClientRiskList,
  getClientRevenueList,
  getClientRevenueCount,
  updateClientRevenue,
  deleteClientRevenue,
  createClientRevenue,
  getClientBenefitList,
  getClientBenefitCount,
  updateClientBenefit,
  deleteClientBenefit,
  createClientBenefit,
  getClientDebtList,
  getClientDebtCount,
  updateClientDebt,
  deleteClientDebt,
  createClientDebt,
  getClientNoteList,
  getClientNoteCount,
  updateClientNote,
  createClientNote,
  getClientCareList,
  getClientCareCount,
  getClientCare,
  updateClientCare,
  createClientCare,
  getClientFolderList,
  getClientFolderCount,
  getClientFolder,
  createFolder,
  updateFolder,
  getClientDocumentList,
  getClientDocumentCount,
  addClientDocumentFromTemplate,
  getClientDocumentUploadUrl,
  addClientDocument,
  getClientDocumentDownloadUrl,
  updateClientDocument,
  getClientContactPersonList,
  getClientContactPersonCount,
  getClientContactPerson,
  updateClientContactPerson,
  createClientContactPerson,
  getClientActionList,
  getClientActionCount,
  getClientRightGroupList,
  getClientRightGroupCount,
  createClientRightGroup,
  deleteClientRightGroup,
  getClientRightUserList,
  getClientRightUserCount,
  createClientRightUser,
  deleteClientRightUser,
  clientSendGdprAgreementCode,
  clientAddGdprAgreement,
};

export default clientApi;
