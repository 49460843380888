import { rgba } from "polished";

//https://designsystem.gov.cz/
const SOURCE_COLORS = {
  //Primary
  govColorBlue: "#2362a2",
  govColorGreyDark: "#3b3b3b",
  govColorWhite: "#ffffff",

  //Blue
  govColorBlueDark: "#254e80",
  //govColorBlue: "#2362a2",
  govColorBlueLight: "#3077b7",
  govColorBlueInactive1: "#91b0d0",
  govColorBlueInactive2: "#d3dfec",
  govColorBlueHover: "#e5ebf0",
  govColorBlueFocus: "#007bff",

  //Neutral
  //govColorGreyDark: "#3b3b3b",
  govColorGreyMid: "#686868",
  govColorGreyLight: "#a8a8a8",
  govColorGreyBg: "#f5f5f5",
  //govColorWhite: "#ffffff",

  //Yellow
  govColorYellow: "#ecae1a",
  govColorYellowDark: "#dd9f0c",

  //Warning
  govColorError: "#c52a3a",
  govColorSuccess: "#6fbd2c",
};

//todo: colors to SOURCE_COLORS
export const COLORS = {
  background: SOURCE_COLORS.govColorWhite,

  headerBg: "#06132B",
  headerBgHover: "#446777",
  headerBgActive: "#446777",
  headerColor: "#FFFFFF",
  headerColorHover: "#FFFFFF",
  headerColorActive: "#FFFFFF",

  menuBg: "#06132B",
  menuBgHover: "#446777",
  menuBgActive: "#446777",
  menuShadow: SOURCE_COLORS.govColorGreyDark,
  menuColor: "#FFFFFF",
  menuColorHover: "#FFFFFF",
  menuColorActive: "#FFFFFF",

  sidebarBg: "#3E557E",
  sidebarBgHover: "#0E2A5E",
  sidebarBgActive: "#0E2A5E",
  sidebarColor: "#FFFFFF",
  sidebarColorHover: "#FFFFFF",
  sidebarColorActive: "#FFFFFF",

  dashboardWidgetAfterDeadLineTitleBg: rgba("#FF7D7D", 0.7),
  dashboardWidgetCalendarListTitleBg: rgba("#FFEB9A", 0.7),
  dashboardWidgetNotAssignedTitleBg: rgba("#92A6B0", 0.7),
  dashboardWidgetToProcessTitleBg: rgba("#446777", 0.7),

  tableHeaderBg: SOURCE_COLORS.govColorGreyLight,
  tableHeaderColor: SOURCE_COLORS.govColorWhite,
  tableHeaderColorHover: SOURCE_COLORS.govColorGreyMid,
  tableBodyBg: SOURCE_COLORS.govColorWhite,
  tableBodyBgHover: SOURCE_COLORS.govColorGreyBg,
  tableBodyBorder: SOURCE_COLORS.govColorGreyLight,
  tableBodyColorHover: SOURCE_COLORS.govColorGreyDark,
  tablePgBgActive: SOURCE_COLORS.govColorBlueFocus,
  tablePgColorActive: SOURCE_COLORS.govColorWhite,
  tableOrderColor: "#000000",
  tableOrderColorHover: "#4CC2F1",
  tableOrderColorActive: "#4CC2F1",

  titleColor: SOURCE_COLORS.govColorGreyDark,
  textColor: "#3B3B3B",
  loaderColor: SOURCE_COLORS.govColorBlue,
  errorColor: "#e3444c",
  warningColor: SOURCE_COLORS.govColorYellow,
  successColor: "#61BD5B",
  selectedColor: SOURCE_COLORS.govColorWhite,
  disabledColor: SOURCE_COLORS.govColorBlueInactive1,

  border: "#9F9F9F",
  borderFocus: "#4CC2F1",
  borderFocusShadow: "#4CC2F1",
  borderError: "#e3444c",

  panelBg: SOURCE_COLORS.govColorGreyBg,
  selectedBg: SOURCE_COLORS.govColorBlueLight,
  disabledBg: SOURCE_COLORS.govColorBlueInactive2,

  formIconColor: SOURCE_COLORS.govColorGreyMid,
  formIconColorHover: SOURCE_COLORS.govColorGreyDark,
  errorIconColor: SOURCE_COLORS.govColorWhite,
  successIconColor: "#61BD5B",

  buttonPrimaryBg: "#06132B",
  buttonPrimaryBgHover: "#446777",
  buttonPrimaryBorder: "#06132B",
  buttonPrimaryBorderHover: "#446777",
  buttonPrimaryColor: "#FFFFFF",
  buttonPrimaryColorHover: "#FFFFFF",
  buttonSecondaryBg: "#FFFFFF",
  buttonSecondaryBgHover: "#92A6B0",
  buttonSecondaryBorder: "#06132B",
  buttonSecondaryBorderHover: "#92A6B0",
  buttonSecondaryColor: "#06132B",
  buttonSecondaryColorHover: "#FFFFFF",
  buttonDisabledBg: "#FFFFFF",
  buttonDisabledBorder: "#CDCFD3",
  buttonDisabledColor: "#CDCFD3",

  calendarBorder: SOURCE_COLORS.govColorGreyLight,
  calendarAvailableColor: SOURCE_COLORS.govColorWhite,
  calendarAvailableBg: SOURCE_COLORS.govColorBlueLight,
  calendarAvailableHover: SOURCE_COLORS.govColorBlueDark,
  calendarUnavailableColor: SOURCE_COLORS.govColorWhite,
  calendarUnavailableBg: "#ED5959",
  calendarUnavailableHover: SOURCE_COLORS.govColorBlueDark,
  calendarActualDayBg: SOURCE_COLORS.govColorBlueLight,
  calendarButtonsColor: SOURCE_COLORS.govColorWhite,
  calendarButtonsBg: SOURCE_COLORS.govColorBlueLight,

  workflowDiagramStartEndColor: "#ffffff",
  workflowDiagramTemplateColor: "#06132b",
  workflowDiagramMultipleColor: "#000000",
  workflowDiagramStartEndBg: "#0e2a5e",
  workflowDiagramTemplateBg: "#ffffff",
  workflowDiagramMultipleBg: "#fdde62",
  workflowDiagramTemplateBorder: "#06132b",
  workflowDiagramMultipleBorder: "#000000",
  workflowDiagramMultiplePortBg: "#cdcfd3",

  toastSuccessBg: "#61BD5B",
  toastSuccessColor: SOURCE_COLORS.govColorWhite,
  toastWarningBg: SOURCE_COLORS.govColorYellow,
  toastWarningColor: SOURCE_COLORS.govColorWhite,
  toastErrorBg: "#e3444c",
  toastErrorColor: SOURCE_COLORS.govColorWhite,

  suggestionHighlightedBg: SOURCE_COLORS.govColorBlueInactive2,

  hrColor: "#CDCFD3",

  transparent: "transparent",
};
