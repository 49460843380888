import { CancelToken } from "axios";
import { FC, ReactElement, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { connect } from "react-redux";
import { IClientNoteItem } from "../../models/client";
import { IApplicationState } from "../../store";
import { IFilterType } from "../../store/filterType";
import {
  changeClientNoteListFilter,
  changeClientNoteListOrder,
  changeClientNoteListPage,
  getClientNoteList,
  getClientNoteListCount,
  IClientNoteListState,
  selectClientNoteList,
  clientNoteListLoad,
} from "../../store/clientNoteList";
import FilterPanel from "../common/filter/FilterPanel";
import Grid from "../common/grid/Grid";
import { GridIconAdd, GridIconEdit } from "../common/grid/GridIcons";
import {
  TableCol,
  TableColExtraText,
  TableColNoWrap,
} from "../common/grid/TableCol";
import { RightType } from "../../models/auth";
import { hasClientWriteRight } from "../../utils/rights";
import {
  selectIdentityId,
  selectIdentityName,
  selectIdentityRights,
} from "../../store/identity";
import { selectClientId } from "../../store/client";
import { Form, Formik, useFormikContext } from "formik";
import Input from "../common/form/Input";
import { GridInlineButtons } from "../common/grid/GridInline";
import * as Yup from "yup";
import clientApi from "../../api/client";
import validations from "../../utils/validations";
import { format } from "date-fns";
import RepairLineBreaks from "../common/RepairLineBreaks";
import { DATE_FORMAT } from "../../utils/consts";
import { promiseToastSaveNoException } from "../../utils/toasts";
import { VerticalGap } from "../../styles/spaces";

const defaultData = {
  id: 0,
  title: "",
  description: "",
  createdAt: new Date(),
} as IClientNoteItem;

interface IProps {
  prov: IClientNoteListState;
  clientId?: number;
  identityId?: number;
  identityName?: string;
  identityRights?: RightType[];
  getClientNoteList(clientId: number, cancelToken: CancelToken): void;
  getClientNoteListCount(clientId: number, cancelToken: CancelToken): void;
  changeClientNoteListOrder(orderBy: string, orderDesc: boolean): void;
  changeClientNoteListPage(page: number): void;
  changeClientNoteListFilter(filter: IFilterType): void;
  clientNoteListLoad(reload: boolean): void;
}

const ClientNoteGridEditor: FC<{
  data: IClientNoteItem;
  handleCancel(): void;
}> = ({ data, handleCancel }) => {
  const {
    errors,
    touched,
    isSubmitting,
    handleSubmit,
    resetForm,
    setFieldValue,
  } = useFormikContext<IClientNoteItem>();

  useEffect(() => {
    resetForm();
    setFieldValue("title", data.title);
    setFieldValue("description", data.description);
  }, [data, resetForm, setFieldValue]);

  return (
    <>
      <TableCol>{format(data.createdAt, DATE_FORMAT)}</TableCol>
      <TableColNoWrap>{data.creator!.name}</TableColNoWrap>
      <td colSpan={2}>
        <VerticalGap className="width100 nowrap">
          <Input
            name="title"
            error={touched.title && !!errors.title}
            maxLength={256}
          />
          <Input
            name="description"
            component="textarea"
            error={touched.description && !!errors.description}
            inputHeight="10rem"
            maxLength={1024}
          />
        </VerticalGap>
      </td>
      <GridInlineButtons
        handleCancel={handleCancel}
        isSubmitting={isSubmitting}
        handleSubmit={handleSubmit}
      />
    </>
  );
};

const ClientNote: FC<IProps> = ({
  prov,
  clientId,
  identityId,
  identityName,
  identityRights,
  getClientNoteList,
  getClientNoteListCount,
  changeClientNoteListOrder,
  changeClientNoteListPage,
  changeClientNoteListFilter,
  clientNoteListLoad,
}) => {
  const { t } = useTranslation();
  const [inlineEditId, setInlineEditId] = useState<number | undefined>();
  const [inlineAdd, setInlineAdd] = useState(false);

  useEffect(() => {
    clientNoteListLoad(false);
  }, [clientNoteListLoad]);

  const handleEdit = (id: number) => {
    setInlineAdd(false);
    setInlineEditId(id);
  };

  const handleCancel = () => {
    setInlineEditId(undefined);
    setInlineAdd(false);
  };

  const handleAdd = () => {
    setInlineEditId(undefined);
    setInlineAdd(true);
  };

  const handleRenderData = (item: IClientNoteItem): ReactElement => {
    return (
      <>
        <TableCol>{format(item.createdAt, DATE_FORMAT)}</TableCol>
        <TableColNoWrap>{item.creator!.name}</TableColNoWrap>
        <TableColNoWrap>{item.title}</TableColNoWrap>
        <TableColExtraText>
          <RepairLineBreaks text={item.description} />
        </TableColExtraText>
        <TableCol>
          {!inlineEditId &&
            !inlineAdd &&
            identityId === item.creator?.id &&
            hasClientWriteRight(identityRights, [
              RightType.WriteClientNotes,
            ]) && <GridIconEdit onClick={() => handleEdit(item.id)} />}
        </TableCol>
      </>
    );
  };

  const handleSubmit = async (data: IClientNoteItem) => {
    await promiseToastSaveNoException(async () => {
      if (inlineEditId) {
        await clientApi.updateClientNote(inlineEditId, data);
      } else {
        await clientApi.createClientNote(clientId!, data);
      }
      clientNoteListLoad(true);
      handleCancel();
    });
  };

  const handleRenderEditor = (item: IClientNoteItem): ReactElement => {
    const data = {
      ...item,
      createdAt: inlineAdd ? new Date() : item.createdAt,
      creator: inlineAdd
        ? { id: identityId!, name: identityName! }
        : item.creator,
    };

    return <ClientNoteGridEditor data={data} handleCancel={handleCancel} />;
  };

  const handleRowClick = (item: IClientNoteItem) => {
    setInlineEditId(item.id);
  };

  const handleGetData = (cancelToken: CancelToken) =>
    getClientNoteList(clientId!, cancelToken);

  const handleGetCount = (cancelToken: CancelToken) =>
    getClientNoteListCount(clientId!, cancelToken);

  return (
    <>
      <FilterPanel
        viewFiltration={!inlineEditId && !inlineAdd}
        name="clientNote"
        filter={prov.filter!}
        changeFilter={changeClientNoteListFilter}
      />
      <Formik
        initialValues={defaultData}
        validationSchema={Yup.object({
          title: validations.stringRequired(t),
          description: validations.stringRequired(t),
        })}
        validateOnMount={true}
        onSubmit={handleSubmit}
      >
        <Form>
          <Grid<IClientNoteItem>
            headers={[
              { captionStr: "client.info.createdAt", minContent: true },
              { captionStr: "client.info.creator", minContent: true },
              { captionStr: "client.info.title", minContent: true },
              { captionStr: "client.info.description" },
              {
                captionEl:
                  !inlineEditId &&
                  !inlineAdd &&
                  hasClientWriteRight(identityRights, [
                    RightType.WriteClientNotes,
                  ]) ? (
                    <GridIconAdd onClick={handleAdd} />
                  ) : undefined,
              },
            ]}
            inlineEditId={inlineEditId}
            inlineAdd={inlineAdd}
            defaultData={defaultData}
            renderData={handleRenderData}
            renderEditor={handleRenderEditor}
            getData={handleGetData}
            getCount={handleGetCount}
            changeOrder={changeClientNoteListOrder}
            changePage={changeClientNoteListPage}
            onRowClick={
              hasClientWriteRight(identityRights, [RightType.WriteClientNotes])
                ? handleRowClick
                : undefined
            }
            prov={prov}
          />
        </Form>
      </Formik>
    </>
  );
};

const mapStateToProps = (state: IApplicationState) => {
  return {
    prov: selectClientNoteList(state),
    clientId: selectClientId(state),
    identityId: selectIdentityId(state),
    identityName: selectIdentityName(state),
    identityRights: selectIdentityRights(state),
  };
};

const mapDispachToProps = {
  getClientNoteList,
  getClientNoteListCount,
  changeClientNoteListOrder,
  changeClientNoteListPage,
  changeClientNoteListFilter,
  clientNoteListLoad,
};

export default connect(mapStateToProps, mapDispachToProps)(ClientNote);
