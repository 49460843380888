import * as ReselectModule from "reselect";
import { IApplicationState } from "..";
import { IClient } from "../../models/client";
import { StoreState } from "../storeState";

export const selectClientState: ReselectModule.Selector<
  IApplicationState,
  StoreState
> = (state) => state.client.state;

export const selectClient: ReselectModule.Selector<
  IApplicationState,
  IClient | null
> = (state) => state.client.data;

export const selectClientId: ReselectModule.Selector<
  IApplicationState,
  number | undefined
> = (state) => state.client.data?.id;
