import { Form } from "formik";
import styled, { css } from "styled-components";
import { COLORS } from "./colors";
import { SIZES } from "./sizes";

export const InputRow = styled.div<{ half?: boolean }>`
  width: 100%;
  display: flex;
  flex-direction: column;

  label {
    margin-bottom: 0.5rem;
  }

  @media screen and (min-width: ${SIZES.mobile}) {
    width: ${({ half }) => (half ? "50%" : "100%")};
    align-items: center;
    flex-direction: row;

    label {
      margin-bottom: 0;
      margin-right: 1rem;
    }
  }
`;

export const InputContainer = styled.div`
  display: flex;
  width: 100%;
  align-items: center;
  position: relative;

  /* Hide arrows on number type. */
  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    appearance: none;
    margin: 0;
  }

  /* Hide arrows on number type. */
  input[type="number"] {
    -moz-appearance: textfield;
  }

  .error {
    color: var(--error-color);
  }

  .warning {
    color: var(--warning-color);
  }
`;

export const InputGroupContainer = styled.div<{
  gridTemplateColumns?: string;
}>`
  display: grid;
  width: 100%;
  grid-template-columns: ${({ gridTemplateColumns }) => gridTemplateColumns};
  gap: 0.5rem;
`;

export const FormGroup = styled.div<{ labelWidth?: string }>`
  width: 100%;
  max-width: 41rem;

  > h2 {
    font-size: 1.2rem;
    text-transform: uppercase;
  }

  > :not(:last-child) {
    margin-bottom: 0.75rem;
  }

  @media screen and (min-width: ${SIZES.mobile}) {
    ${InputRow} label {
      width: ${({ labelWidth }) => labelWidth || "18rem"};
    }
  }

  > hr {
    height: 0.75rem;
    margin-top: 1.5rem;
  }
`;

export const FormRow = styled.div<{
  gridTemplateColumns: string;
}>`
  display: grid;
  grid-template-columns: ${({ gridTemplateColumns }) => gridTemplateColumns};
  gap: 1.5rem;
  margin: 0 1rem;
`;

export const FormDesktop = styled(Form)`
  display: none;
  flex-direction: column;
  gap: 1.5rem;
  @media screen and (min-width: ${SIZES.tablet}) {
    display: flex;
  }
`;

export const FormTablet = styled(Form)`
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
  @media screen and (min-width: ${SIZES.tablet}) {
    display: none;
  }
`;

export const FormDesktopDiv = styled.div`
  display: none;

  @media screen and (min-width: ${SIZES.laptop}) {
    display: block;
  }
`;

export const FormLaptopDiv = styled.div`
  display: block;

  @media screen and (min-width: ${SIZES.laptop}) {
    display: none;
  }
`;

export const textInput = (props: {
  $error?: boolean;
  width?: string;
  height?: string;
}) => css`
  height: ${props.height || "2.5rem"};
  width: ${props.width || "100%"};
  padding: 0.5rem 0.75rem;

  border: 1px solid ${props.$error ? COLORS.borderError : COLORS.border};
  border-radius: 5px;
  outline: none;
  color: var(--text-color);
  font-size: 1rem;

  transition: all 0.15s ease-in-out;

  &:focus {
    border-color: var(--border-focus);
    box-shadow: 0 0 3px 0 var(--border-focus-shadow);
  }

  &::placeholder {
    color: var(--text-color);
    opacity: 1;
  }

  &:disabled {
    background-color: var(--disabled-bg) !important;
    color: var(--disabled-color) !important;
    opacity: 1 !important;

    ::placeholder {
      color: var(--disabled-color) !important;
    }
  }
`;

export const ApiError = styled.div`
  margin-top: 1.5rem;
  color: var(--error-color);
`;

export const ExplanatoryNote = styled.div`
  margin-bottom: 0.5rem;
  color: var(--error-color);
`;
