import { CancelToken } from "axios";
import { FC, ReactElement, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { connect } from "react-redux";
import { DemandPriorityType, IDemandItem } from "../../models/demand";
import { IApplicationState } from "../../store";
import { IFilterType } from "../../store/filterType";
import {
  changeClientDemandListFilter,
  changeClientDemandListOrder,
  changeClientDemandListPage,
  getClientDemandList,
  getClientDemandListCount,
  IClientDemandListState,
  selectClientDemandList,
  clientDemandListLoad,
} from "../../store/clientDemandList";
import FilterPanel from "../common/filter/FilterPanel";
import Grid from "../common/grid/Grid";
import { GridIconAdd, GridIconEdit } from "../common/grid/GridIcons";
import { TableCol } from "../common/grid/TableCol";
import { RightType } from "../../models/auth";
import { hasClientWriteRight } from "../../utils/rights";
import { selectIdentityRights } from "../../store/identity";
import { selectClientId } from "../../store/client";
import { useNavigate, useParams } from "react-router";
import { NavLink } from "react-router-dom";
import { getClassByDate } from "../../utils/date";
import { DATE_TIME_FORMAT } from "../../utils/consts";
import { format } from "date-fns";

interface IProps {
  prov: IClientDemandListState;
  clientId?: number;
  identityRights?: RightType[];
  getClientDemandList(clientId: number, cancelToken: CancelToken): void;
  getClientDemandListCount(clientId: number, cancelToken: CancelToken): void;
  changeClientDemandListOrder(orderBy: string, orderDesc: boolean): void;
  changeClientDemandListPage(page: number): void;
  changeClientDemandListFilter(filter: IFilterType): void;
  clientDemandListLoad(reload: boolean): void;
}

const ClientDemand: FC<IProps> = ({
  prov,
  clientId,
  identityRights,
  getClientDemandList,
  getClientDemandListCount,
  changeClientDemandListOrder,
  changeClientDemandListPage,
  changeClientDemandListFilter,
  clientDemandListLoad,
}) => {
  const { t } = useTranslation();
  const { clientId: paramClientId } = useParams();
  const navigate = useNavigate();

  useEffect(() => {
    clientDemandListLoad(false);
  }, [clientDemandListLoad]);

  const handleRenderData = (item: IDemandItem): ReactElement => {
    return (
      <>
        <TableCol>{t("demand.states." + item.state)}</TableCol>
        <TableCol>
          <span className={getClassByDate(item.deadLine)}>
            {format(item.deadLine, DATE_TIME_FORMAT)}
          </span>
        </TableCol>
        <TableCol>
          <span
            className={
              item.priority === DemandPriorityType.High ? "error" : undefined
            }
          >
            {t("demand.priorities." + item.priority)}
          </span>
        </TableCol>
        <TableCol>{item.name}</TableCol>
        <TableCol>{item.assignedName}</TableCol>
        <TableCol>
          <NavLink to={`/client/${paramClientId}/demand/${item.id}`}>
            <GridIconEdit />
          </NavLink>
        </TableCol>
      </>
    );
  };

  const handleRowClick = (item: IDemandItem) => {
    navigate(`/client/${paramClientId}/demand/${item.id}`);
  };

  const handleGetData = (cancelToken: CancelToken) =>
    getClientDemandList(clientId!, cancelToken);

  const handleGetCount = (cancelToken: CancelToken) =>
    getClientDemandListCount(clientId!, cancelToken);

  return (
    <>
      <FilterPanel
        name="clientDemand"
        filter={prov.filter!}
        changeFilter={changeClientDemandListFilter}
      />
      <Grid<IDemandItem>
        headers={[
          { captionStr: "client.demand.state" },
          { captionStr: "client.demand.deadLine" },
          { captionStr: "client.demand.priority" },
          { captionStr: "client.demand.name" },
          { captionStr: "client.demand.assignedName" },
          {
            captionEl: hasClientWriteRight(identityRights, [
              RightType.WriteClientDemand,
            ]) ? (
              <NavLink to={`/client/${paramClientId}/demand/new`}>
                <GridIconAdd />
              </NavLink>
            ) : undefined,
          },
        ]}
        renderData={handleRenderData}
        getData={handleGetData}
        getCount={handleGetCount}
        changeOrder={changeClientDemandListOrder}
        changePage={changeClientDemandListPage}
        onRowClick={handleRowClick}
        prov={prov}
      />
    </>
  );
};

const mapStateToProps = (state: IApplicationState) => {
  return {
    prov: selectClientDemandList(state),
    clientId: selectClientId(state),
    identityRights: selectIdentityRights(state),
  };
};

const mapDispachToProps = {
  getClientDemandList,
  getClientDemandListCount,
  changeClientDemandListOrder,
  changeClientDemandListPage,
  changeClientDemandListFilter,
  clientDemandListLoad,
};

export default connect(mapStateToProps, mapDispachToProps)(ClientDemand);
