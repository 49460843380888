import { AxiosResponse } from "axios";
import { IDocumentEditOnlineUrl } from "../models/document";
import api from "../utils/api";

const getEditOnlineUrl = async (
  documentId: number
): Promise<AxiosResponse<IDocumentEditOnlineUrl>> =>
  api.get(`/api/Document/${documentId}/EditOnline`);

const documentApi = {
  getEditOnlineUrl,
};

export default documentApi;
