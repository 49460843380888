import { CancelToken } from "axios";
import { Form, Formik, useFormikContext } from "formik";
import { FC, ReactElement, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { connect } from "react-redux";
import { IListingEdit, IListingItem, ListingType } from "../../models/listing";
import { IApplicationState } from "../../store";
import { IFilterType } from "../../store/filterType";
import {
  changeListingListFilter,
  changeListingListOrder,
  changeListingListPage,
  getListingList,
  getListingListCount,
  IListingListItems,
  selectListingList,
  listingListLoad,
} from "../../store/listingList";
import FilterPanel from "../common/filter/FilterPanel";
import Grid from "../common/grid/Grid";
import {
  GridIconAdd,
  GridIconEdit,
  GridIconIsActive,
} from "../common/grid/GridIcons";
import {
  TableCol,
  TableColCenter,
  TableColInput,
} from "../common/grid/TableCol";
import * as Yup from "yup";
import Input from "../common/form/Input";
import { GridInlineButtons } from "../common/grid/GridInline";
import listingApi from "../../api/listing";
import validations from "../../utils/validations";
import { hasRight } from "../../utils/rights";
import { RightType } from "../../models/auth";
import { selectIdentityRights } from "../../store/identity";
import { promiseToastSaveNoException } from "../../utils/toasts";

const defaultData = {
  id: 0,
  isActive: true,
  name: "",
} as IListingItem;

interface IProps {
  type: ListingType;
  writeRight: RightType;
  prov: IListingListItems;
  identityRights?: RightType[];
  getListingList(type: ListingType, cancelToken: CancelToken): void;
  getListingListCount(type: ListingType, cancelToken: CancelToken): void;
  changeListingListOrder(
    type: ListingType,
    orderBy: string,
    orderDesc: boolean
  ): void;
  changeListingListPage(type: ListingType, page: number): void;
  changeListingListFilter(type: ListingType, filter: IFilterType): void;
  listingListLoad(type: ListingType, reload: boolean): void;
}

const AdminListingGridEditor: FC<{ data: IListingEdit; handleCancel(): void }> =
  ({ data, handleCancel }) => {
    const {
      errors,
      touched,
      isSubmitting,
      handleSubmit,
      resetForm,
      setFieldValue,
    } = useFormikContext<IListingEdit>();

    useEffect(() => {
      resetForm();
      setFieldValue("name", data.name);
      setFieldValue("isActive", data.isActive);
    }, [data, resetForm, setFieldValue]);

    return (
      <>
        <TableColCenter>
          <Input
            name="isActive"
            type="checkbox"
            inputWidth="1.5rem"
            inputHeight="1.5rem"
          />
        </TableColCenter>
        <TableColInput>
          <Input
            name="name"
            error={touched.name && !!errors.name}
            maxLength={255}
          />
        </TableColInput>
        <GridInlineButtons
          handleCancel={handleCancel}
          isSubmitting={isSubmitting}
          handleSubmit={handleSubmit}
        />
      </>
    );
  };

const AdminListingGrid: FC<IProps> = ({
  type,
  writeRight,
  prov,
  identityRights,
  getListingList,
  getListingListCount,
  changeListingListOrder,
  changeListingListPage,
  changeListingListFilter,
  listingListLoad,
}) => {
  const { t } = useTranslation();
  const [isExpanded, setIsExpanded] = useState(false);
  const [inlineEditId, setInlineEditId] = useState<number | undefined>();
  const [inlineAdd, setInlineAdd] = useState(false);

  useEffect(() => {
    listingListLoad(type, false);
  }, [listingListLoad, type]);

  const handleEdit = (id: number) => {
    setInlineAdd(false);
    setInlineEditId(id);
  };

  const handleCancel = () => {
    setInlineEditId(undefined);
    setInlineAdd(false);
  };

  const handleAdd = () => {
    setInlineEditId(undefined);
    setInlineAdd(true);
  };

  const handleRenderData = (item: IListingItem): ReactElement => {
    return (
      <>
        <TableColCenter>{item.isActive && <GridIconIsActive />}</TableColCenter>
        <TableCol>{item.name}</TableCol>
        <TableCol>
          {!inlineEditId &&
            !inlineAdd &&
            hasRight(identityRights, [writeRight]) && (
              <GridIconEdit onClick={() => handleEdit(item.id)} />
            )}
        </TableCol>
      </>
    );
  };

  const handleSubmit = async (data: IListingEdit) => {
    await promiseToastSaveNoException(async () => {
      if (inlineEditId) {
        await listingApi.updateListing(inlineEditId, data);
      } else {
        await listingApi.addListing(type, data);
      }

      listingListLoad(type, true);
      handleCancel();
    });
  };

  const handleRenderEditor = (item: IListingItem): ReactElement => {
    return <AdminListingGridEditor data={item} handleCancel={handleCancel} />;
  };

  const handleRowClick = (item: IListingItem) => {
    setInlineEditId(item.id);
  };

  const handleGetData = (cancelToken: CancelToken) =>
    getListingList(type, cancelToken);

  const handleGetCount = (cancelToken: CancelToken) =>
    getListingListCount(type, cancelToken);

  const handleChangeOrder = (orderBy: string, orderDesc: boolean) =>
    changeListingListOrder(type, orderBy, orderDesc);

  const handleChangePage = (page: number) => changeListingListPage(type, page);

  const handleChangeFilter = (filter: IFilterType) =>
    changeListingListFilter(type, filter);

  return (
    <>
      <FilterPanel
        viewFiltration={!inlineEditId && !inlineAdd}
        title={t("admin.listing." + type)}
        isExpanded={isExpanded}
        setIsExpanded={setIsExpanded}
        name={"adminListing" + type}
        filter={prov.filter!}
        changeFilter={handleChangeFilter}
      />

      {isExpanded && (
        <Formik
          initialValues={{ name: "", isActive: true }}
          validationSchema={Yup.object({
            name: validations.stringRequired(t),
          })}
          validateOnMount={true}
          onSubmit={handleSubmit}
        >
          <Form>
            <Grid<IListingItem>
              headers={[
                { captionStr: "admin.listing.isActive", minContent: true },
                { captionStr: "admin.listing.name" },
                {
                  captionEl:
                    !inlineEditId &&
                    !inlineAdd &&
                    hasRight(identityRights, [writeRight]) ? (
                      <GridIconAdd onClick={handleAdd} />
                    ) : undefined,
                },
              ]}
              inlineEditId={inlineEditId}
              inlineAdd={inlineAdd}
              defaultData={defaultData}
              renderData={handleRenderData}
              renderEditor={handleRenderEditor}
              getData={handleGetData}
              getCount={handleGetCount}
              changeOrder={handleChangeOrder}
              changePage={handleChangePage}
              onRowClick={
                hasRight(identityRights, [writeRight])
                  ? handleRowClick
                  : undefined
              }
              prov={prov}
            />
          </Form>
        </Formik>
      )}
    </>
  );
};

const mapStateToProps = (
  state: IApplicationState,
  { type }: { type: ListingType }
) => {
  return {
    prov: selectListingList(state, type),
    identityRights: selectIdentityRights(state),
  };
};

const mapDispachToProps = {
  getListingList,
  getListingListCount,
  changeListingListOrder,
  changeListingListPage,
  changeListingListFilter,
  listingListLoad,
};

export default connect(mapStateToProps, mapDispachToProps)(AdminListingGrid);
