import { FC, useState } from "react";
import styled from "styled-components";
import { GridIconCollapse, GridIconExpand } from "./GridIcons";

export const TableColContainer = styled.div`
  display: flex;
`;

export const TableCol: FC = ({ children }) => {
  return (
    <td>
      <TableColContainer>{children}</TableColContainer>
    </td>
  );
};

export const TableColCenterContainer = styled.div`
  margin: 0 auto;
  width: max-content;
`;

export const TableColCenter: FC = ({ children }) => {
  return (
    <td>
      <TableColCenterContainer>{children}</TableColCenterContainer>
    </td>
  );
};

export const TableColInput: FC = ({ children }) => {
  return (
    <td>
      <div className="width100">{children}</div>
    </td>
  );
};

export const TableColNoWrap: FC = ({ children }) => {
  return (
    <td>
      <div className="nowrap">{children}</div>
    </td>
  );
};

export const TableColExtraTextContainer = styled.td`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;
export const ExpandControlWrapper = styled.div`
  align-self: start;
`;

export const TableColExtraText: FC = ({ children }) => {
  const [isExpanded, setIsExpanded] = useState(false);

  return (
    <TableColExtraTextContainer>
      <div className={isExpanded ? "width100" : "extra-text"}>{children}</div>
      <ExpandControlWrapper>
        {isExpanded ? (
          <GridIconCollapse onClick={() => setIsExpanded((value) => !value)} />
        ) : (
          <GridIconExpand onClick={() => setIsExpanded((value) => !value)} />
        )}
      </ExpandControlWrapper>
    </TableColExtraTextContainer>
  );
};
