import { AxiosResponse, CancelToken } from "axios";
import { FC, ReactElement, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { connect } from "react-redux";
import { NavLink, useNavigate } from "react-router-dom";
import { ClientStateType, IClientItem } from "../../models/client";
import { IApplicationState } from "../../store";
import { IFilterType } from "../../store/filterType";
import {
  changeClientListFilter,
  changeClientListOrder,
  changeClientListPage,
  getClientList,
  getClientListCount,
  IClientListState,
  selectClientList,
  clientListLoad,
  addClientParams,
} from "../../store/clientList";
import FilterPanel from "../common/filter/FilterPanel";
import Grid from "../common/grid/Grid";
import { GridIconAdd, GridIconDetail } from "../common/grid/GridIcons";
import { TableCol, TableColCenter } from "../common/grid/TableCol";
import Page from "../layout/Page";
import { RightType } from "../../models/auth";
import { hasClientWriteRight } from "../../utils/rights";
import { selectIdentityRights } from "../../store/identity";
import {
  handleLoadAssistanceSuggestions,
  handleLoadUserSuggestions,
} from "../../utils/suggestions";
import SuggestionFormik from "../common/suggestion/SuggestionFormik";
import Input from "../common/form/Input";
import { ExportType } from "../../models/common";
import clientApi from "../../api/client";
import {
  listEffectGetFilter,
  listEffectsGetParamsForExport,
} from "../../store/listEffect";
import { getClientStateIcon } from "./ClientHelpers";
import MultiCheckInput from "../common/form/MultiCheckInput";

interface IProps {
  prov: IClientListState;
  identityRights?: RightType[];
  getClientList(cancelToken: CancelToken): void;
  getClientListCount(cancelToken: CancelToken): void;
  changeClientListOrder(orderBy: string, orderDesc: boolean): void;
  changeClientListPage(page: number): void;
  changeClientListFilter(filter: IFilterType): void;
  clientListLoad(reload: boolean): void;
}

const Client: FC<IProps> = ({
  prov,
  identityRights,
  getClientList,
  getClientListCount,
  changeClientListOrder,
  changeClientListPage,
  changeClientListFilter,
  clientListLoad,
}) => {
  const { t } = useTranslation();

  useEffect(() => {
    clientListLoad(false);
  }, [clientListLoad]);

  const navigate = useNavigate();

  const handleRenderData = (item: IClientItem): ReactElement => {
    return (
      <>
        <TableColCenter>{getClientStateIcon(item.state, t)}</TableColCenter>
        <TableCol>{item.title}</TableCol>
        <TableCol>{item.firstName}</TableCol>
        <TableCol>{item.lastName}</TableCol>
        <TableCol>{item.degree}</TableCol>
        <TableCol>
          <NavLink to={`/client/${item.id}/general`}>
            <GridIconDetail />
          </NavLink>
        </TableCol>
      </>
    );
  };

  const handleRowClick = (item: IClientItem) => {
    navigate(`/client/${item.id}/general`);
  };

  const handleExport = async (
    type: ExportType
  ): Promise<AxiosResponse<Blob>> => {
    const params = listEffectsGetParamsForExport(prov);
    listEffectGetFilter(params, prov.filter);
    addClientParams(params, prov);

    if (type === ExportType.Csv) {
      return await clientApi.clientListExportCsv(params);
    }

    return await clientApi.clientListExportXlsx(params);
  };

  return (
    <Page>
      <FilterPanel
        title={t("client.pageTitle")}
        name="client"
        filter={prov.filter!}
        changeFilter={changeClientListFilter}
        doExport={handleExport}
        initialValues={{
          isStateBasic: prov.filter!.isStateBasic,
          isStateNew: prov.filter!.isStateNew,
          isStateActive: prov.filter!.isStateActive,
          isStateInActive: prov.filter!.isStateInActive,
          isStateRejected: prov.filter!.isStateRejected,
          isStateDead: prov.filter!.isStateDead,
          isStateAnonymised: prov.filter!.isStateAnonymised,
          keyCaregiverId: prov.filter!.keyCaregiverId,
          keyCaregiverName: prov.filter!.keyCaregiverName,
          assistanceId: prov.filter!.assistanceId,
          assistanceName: prov.filter!.assistanceName,
        }}
      >
        <MultiCheckInput label={t("client.state")}>
          <Input
            textAfter={t("client.states." + ClientStateType.Basic)}
            name="isStateBasic"
            type="checkbox"
            inputWidth="1.5rem"
            inputHeight="1.5rem"
          />
          <Input
            textAfter={t("client.states." + ClientStateType.New)}
            name="isStateNew"
            type="checkbox"
            inputWidth="1.5rem"
            inputHeight="1.5rem"
          />
          <Input
            textAfter={t("client.states." + ClientStateType.Active)}
            name="isStateActive"
            type="checkbox"
            inputWidth="1.5rem"
            inputHeight="1.5rem"
          />
          <Input
            textAfter={t("client.states." + ClientStateType.InActive)}
            name="isStateInActive"
            type="checkbox"
            inputWidth="1.5rem"
            inputHeight="1.5rem"
          />
          <Input
            textAfter={t("client.states." + ClientStateType.Rejected)}
            name="isStateRejected"
            type="checkbox"
            inputWidth="1.5rem"
            inputHeight="1.5rem"
          />
          <Input
            textAfter={t("client.states." + ClientStateType.Dead)}
            name="isStateDead"
            type="checkbox"
            inputWidth="1.5rem"
            inputHeight="1.5rem"
          />
          <Input
            textAfter={t("client.states." + ClientStateType.Anonymised)}
            name="isStateAnonymised"
            type="checkbox"
            inputWidth="1.5rem"
            inputHeight="1.5rem"
          />
        </MultiCheckInput>

        <SuggestionFormik
          nameId="keyCaregiverId"
          nameText="keyCaregiverName"
          label={t("client.keyCaregiver")}
          loadSuggestions={handleLoadUserSuggestions}
        />
        <SuggestionFormik
          nameId="assistanceId"
          nameText="assistanceName"
          label={t("client.assistance")}
          loadSuggestions={handleLoadAssistanceSuggestions}
        />
      </FilterPanel>
      <Grid<IClientItem>
        headers={[
          { captionStr: "client.state", orderName: "State", minContent: true },
          { captionStr: "client.title", orderName: "Title" },
          { captionStr: "client.firstName", orderName: "FirstName" },
          {
            captionStr: "client.lastName",
            orderName: "LastName",
          },
          { captionStr: "client.degree", orderName: "Degree" },
          {
            captionEl: hasClientWriteRight(identityRights, [
              RightType.CreateClient,
            ]) ? (
              <NavLink to={`/client/new/general`}>
                <GridIconAdd />
              </NavLink>
            ) : undefined,
          },
        ]}
        renderData={handleRenderData}
        getData={getClientList}
        getCount={getClientListCount}
        changeOrder={changeClientListOrder}
        changePage={changeClientListPage}
        onRowClick={handleRowClick}
        prov={prov}
      />
    </Page>
  );
};

const mapStateToProps = (state: IApplicationState) => {
  return {
    prov: selectClientList(state),
    identityRights: selectIdentityRights(state),
  };
};

const mapDispachToProps = {
  getClientList,
  getClientListCount,
  changeClientListOrder,
  changeClientListPage,
  changeClientListFilter,
  clientListLoad,
};

export default connect(mapStateToProps, mapDispachToProps)(Client);
