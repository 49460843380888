import { IItemIdName } from "./common";

export enum ClientStateType {
  Basic = "basic",
  New = "new",
  Active = "active",
  InActive = "inActive",
  Rejected = "rejected",
  Dead = "dead",
  Anonymised = "anonymised",
}

export enum ClientIdentityType {
  IdentityCard = "identityCard",
  Passport = "passport",
  Unverified = "unverified",
}

export enum ClientHouseLivingType {
  Flat = "flat",
  House = "house",
  HalfWayHouse = "halfWayHouse",
  Hostel = "hostel",
  Homeless = "homeless",
}

export enum ClientHouseOwnershipType {
  Personal = "personal",
  Rent = "rent",
  RentalAgreementTermInfinite = "rentalAgreementTermInfinite",
  RentalAgreementTermFinite = "rentalAgreementTermFinite",
  CooperativeApartment = "cooperativeApartment",
  RentOffice = "rentOffice",
  LivingWithRelatives = "livingWithRelatives",
  LivingWithPartner = "livingWithPartner",
  LivingWithAcquaintances = "livingWithAcquaintances",
}

export enum ClientRevenueType {
  InvalidityPension = "invalidityPension",
  Salary = "salary",
  Pension = "pension",
  TemporaryJob = "temporaryJob",
  UnemploymentCompensation = "unemploymentCompensation",
  Alimony = "alimony",
  FinancialHelpInMaternity = "financialHelpInMaternity",
  WidowsPension = "widowsPension",
  OrphansPension = "orphansPension",
  Other = "other",
}

export enum ClientDebtType {
  Loan = "loan",
  Credit = "credit",
  Execution = "execution",
  Insolvency = "insolvency",
}

export enum ClientCareStateType {
  Preparation = "preparation",
  InProgress = "inProgress",
  Postponed = "postponed",
  PrematurelyFinished = "prematurelyFinished",
  Finished = "finished",
}

export interface IClientItem {
  id: number;
  state: ClientStateType;
  title?: string | null;
  firstName: string;
  lastName: string;
  degree?: string | null;
}

export interface IClient {
  id: number;
  state: ClientStateType;
  login?: string | null;
  firstName: string;
  lastName: string;
  title?: string | null;
  degree?: string | null;
  mobilePhone?: string | null;
  email?: string | null;
  phone?: string | null;
  dataBox?: string | null;
  identityType?: ClientIdentityType | null;
  identityCode?: string | null;
  birthDate?: Date | null;
  identificationNumber?: string | null;

  fullRegistration: boolean;

  addressPermanentStreet?: string | null;
  addressPermanentHouseNumber?: number | null;
  addressPermanentLandRegistryNumber?: number | null;
  addressPermanentCity?: string | null;
  addressPermanentPostCode?: number | null;

  addressActualStreet?: string | null;
  addressActualHouseNumber?: number | null;
  addressActualLandRegistryNumber?: number | null;
  addressActualCity?: string | null;
  addressActualPostCode?: number | null;

  addressMailStreet?: string | null;
  addressMailHouseNumber?: number | null;
  addressMailLandRegistryNumber?: number | null;
  addressMailCity?: string | null;
  addressMailPostCode?: number | null;

  addressActualIsSame: boolean;
  addressMailIsSame: boolean;

  keyCaregiver?: IItemIdName | null;
  healthInsurance?: IItemIdName | null;
  gdprAgreementAt?: Date | null;
  gdprAgreementAddedByUser?: IItemIdName | null;
  gdprAgreementWithoutSecret?: boolean | null;
}

export interface IClientHouse {
  livingType?: ClientHouseLivingType | null | "";
  ownershipType?: ClientHouseOwnershipType | null | "";
  floorNumber?: number | null;
  conditions?: string | null;
  barriers?: string | null;
  personsInHousehold?: number | null;
  livingLength?: number | null;
  costPerMonth?: number | null;
  termination?: boolean | null;
}

export interface IClientRiskGrid {
  items: IItemIdName[];
}

export interface IClientRevenueItem {
  id: number;
  type: ClientRevenueType;
  amountPerMonth: number;
  description?: string | null;
}

export interface IClientBenefitItem {
  id: number;
  typeId: number;
  typeName: string;
  amountPerMonth: number;
  description?: string | null;
}

export interface IClientDebtItem {
  id: number;
  type: ClientDebtType;
  amount: number;
  amountPerMonth: number;
  description?: string | null;
}

export interface IClientNoteItem {
  id: number;
  title: string;
  description: string;
  createdAt: Date;
  creator?: IItemIdName | null;
}

export interface IClientCareItem {
  id: number;
  assistanceAddress: IItemIdName;
  careType: IItemIdName;
  from: Date;
  to?: Date | null;
  state: ClientCareStateType;
}

export interface IClientCare {
  id: number;
  client: IItemIdName;
  assistance: IItemIdName;
  assistanceAddress: IItemIdName;
  assistanceCategoryListing: IItemIdName;
  careType: IItemIdName;
  from: Date;
  to?: Date | null;
  state: ClientCareStateType;
  note?: string | null;
}

export interface IClientDocumentItem {
  id: number;
  name: string;
  author: IItemIdName;
  createdAt: Date;
  lastEditedAt: Date;
  lastEditor: IItemIdName;
}

export interface IClientContactPersonItem {
  id: number;
  name: string;
  type: IItemIdName;
  mobilePhone?: string | null;
  phone?: string | null;
  email?: string | null;
}

export interface IClientContactPerson {
  id: number;
  isActive: boolean;
  login?: string | null;
  type: IItemIdName;
  firstName: string;
  lastName: string;
  title?: string | null;
  degree?: string | null;
  mobilePhone?: string | null;
  email?: string | null;
  phone?: string | null;
}
