import { faTimes } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { CancelToken } from "axios";
import { useFormikContext } from "formik";
import { FC, useEffect } from "react";
import { InputContainer, InputRow } from "../../../styles/form";
import FormError from "../form/FormError";
import { InputLabel, InputTextAfter } from "../form/InputStyles";
import RequiredMark from "../form/RequiredMark";
import { ISuggestionValue } from "./Suggestion";
import SuggestionList from "./SuggestionList";
import { SuggestionItem, SuggestionListUl } from "./SuggestionStyles";

interface IProps {
  nameList: string;
  nameId: string;
  nameText: string;
  label?: string;
  textAfter?: string | JSX.Element;
  tooltipLeft?: boolean;
  showListAlways?: boolean;
  autoFocus?: boolean;
  loadSuggestions(
    text: string,
    cancelToken: CancelToken
  ): Promise<ISuggestionValue[]>;
}

const SuggestionListFormik: FC<IProps> = ({
  nameList,
  nameId,
  nameText,
  label,
  textAfter,
  tooltipLeft,
  showListAlways,
  autoFocus,
  loadSuggestions,
}) => {
  const {
    values,
    errors,
    touched,
    setFieldValue,
    validateForm,
    setFieldTouched,
  } = useFormikContext<any>();

  useEffect(() => {
    validateForm();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const error = touched[nameList] && !!errors[nameList];
  const list = values[nameList];
  const suggestionValues = list.map((x: any) => ({
    id: x[nameId],
    text: x[nameText],
  })) as ISuggestionValue[];

  const handleSetValues = (newValues: ISuggestionValue[]) => {
    const formValues = newValues.map((x) => ({
      [nameId]: x.id,
      [nameText]: x.text,
    }));
    setFieldValue(nameList, formValues);
    validateForm({ ...values, [nameList]: formValues });
  };

  const handleDelete = (id: number) => {
    handleSetValues(suggestionValues.filter((x) => x.id !== id));
  };

  const handleBlur = () => {
    setFieldTouched(nameList, true, true);
  };

  return (
    <>
      <InputRow half={label === undefined}>
        {label && (
          <InputLabel htmlFor={nameList}>
            {label}
            {errors[nameList] && <RequiredMark />}
          </InputLabel>
        )}
        <InputContainer>
          <SuggestionList
            name={nameList}
            values={suggestionValues}
            showListAlways={showListAlways}
            autoFocus={autoFocus}
            loadSuggestions={loadSuggestions}
            setValues={handleSetValues}
            onBlur={handleBlur}
          />
          {textAfter && <InputTextAfter>{textAfter}</InputTextAfter>}
          {error && <FormError name={nameList} tooltipLeft={tooltipLeft} />}
        </InputContainer>
      </InputRow>
      <InputRow>
        {label && <label />}
        <InputContainer>
          <SuggestionListUl>
            {suggestionValues.map((x) => (
              <li key={x.id}>
                <SuggestionItem>
                  <span>{x.text}</span>
                  <FontAwesomeIcon
                    icon={faTimes}
                    onClick={() => handleDelete(x.id)}
                  />
                </SuggestionItem>
              </li>
            ))}
          </SuggestionListUl>
        </InputContainer>
      </InputRow>
    </>
  );
};

export default SuggestionListFormik;
