import Providers from "./components/layout/Providers";
import React, { FC, Suspense } from "react";
import BrowserRouterWithHistory from "./components/common/BrowserRouterWithHistory";
import { Provider } from "react-redux";
import { Routes1 } from "./Routes1";
import Layout from "./components/layout/Layout";
import { store } from "./store";

export const App: FC = () => {
  return (
    <Suspense fallback="loading">
      <Provider store={store}>
        <BrowserRouterWithHistory>
          <Providers>
            <Layout>
              <Routes1 />
            </Layout>
          </Providers>
        </BrowserRouterWithHistory>
      </Provider>
    </Suspense>
  );
};
