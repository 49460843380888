import { IconDefinition } from "@fortawesome/fontawesome-common-types";

export enum ExportType {
  Csv = "csv",
  Xlsx = "xlsx",
}

export interface IItemIdName {
  id: number;
  name: string;
}

export interface ISidebarLink {
  key: string;
  to: string;
  text: string;
  icon: IconDefinition;
}
