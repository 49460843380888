import { CancelToken } from "axios";
import { FC, ReactElement, useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { connect } from "react-redux";
import { IClientDocumentItem } from "../../models/client";
import { IApplicationState } from "../../store";
import { IFilterType } from "../../store/filterType";
import {
  changeClientDocumentListFilter,
  changeClientDocumentListOrder,
  changeClientDocumentListPage,
  getClientDocumentList,
  getClientDocumentListCount,
  IClientDocumentListState,
  selectClientDocumentList,
  clientDocumentListLoad,
} from "../../store/clientDocumentList";
import FilterPanel from "../common/filter/FilterPanel";
import Grid from "../common/grid/Grid";
import {
  GridIconAdd,
  GridIconDownload,
  GridIconEdit,
  GridIconFromTemplate,
  GridIconSpace,
  GridIconUpload,
} from "../common/grid/GridIcons";
import { TableCol } from "../common/grid/TableCol";
import { RightType } from "../../models/auth";
import { hasClientWriteRight, hasRight } from "../../utils/rights";
import { selectIdentityRights } from "../../store/identity";
import { selectClientId } from "../../store/client";
import { DivTextRight } from "../../styles/align";
import SelectTemplateModal from "../selectTemplate/SelectTemplateModal";
import { ISelectedFile } from "../selectTemplate/SelectTemplateTree";
import clientApi from "../../api/client";
import { TemplateDocumentType } from "../../models/template";
import bucketApi from "../../api/bucket";
import ClientDocumentAddModal from "./ClientDocumentAddModal";
import { startDocumentEditOnline } from "../document/DocumentEditOnlineStart";
import { promiseToast, promiseToastSave } from "../../utils/toasts";
import SuggestionFormik from "../common/suggestion/SuggestionFormik";
import { handleLoadUserSuggestions } from "../../utils/suggestions";
import {
  CalendarModalTimesContainer,
  CalendarModalTimes,
  CalendarModalTimesLabel,
} from "../calendar/CalendarStyles";
import DatePicker from "../common/form/DatePicker";
import { format } from "date-fns";
import { DATE_TIME_FORMAT } from "../../utils/consts";
import { useParams } from "react-router";

interface IProps {
  prov: IClientDocumentListState;
  clientId?: number;
  identityRights?: RightType[];
  getClientDocumentList(
    clientId: number,
    folderId: string,
    cancelToken: CancelToken
  ): void;
  getClientDocumentListCount(
    clientId: number,
    folderId: string,
    cancelToken: CancelToken
  ): void;
  changeClientDocumentListOrder(orderBy: string, orderDesc: boolean): void;
  changeClientDocumentListPage(page: number): void;
  changeClientDocumentListFilter(filter: IFilterType): void;
  clientDocumentListLoad(reload: boolean): void;
}

const ClientDocumentDocument: FC<IProps> = ({
  prov,
  clientId,
  identityRights,
  getClientDocumentList,
  getClientDocumentListCount,
  changeClientDocumentListOrder,
  changeClientDocumentListPage,
  changeClientDocumentListFilter,
  clientDocumentListLoad,
}) => {
  const { t } = useTranslation();
  const [addFile, setAddFile] = useState<File | null>(null);
  const [addIsOpen, setAddIsOpen] = useState(false);
  const [templateIsOpen, setTemplateIsOpen] = useState(false);
  const [uploadDocumentId, setUploadDocumentId] = useState(0);
  const inputRef = useRef<HTMLInputElement>(null);
  const { folderId } = useParams();

  useEffect(() => {
    clientDocumentListLoad(false);
  }, [clientDocumentListLoad]);

  const handleAdd = () => {
    setUploadDocumentId(0);
    if (inputRef.current) {
      inputRef.current.click();
    }
  };

  const handleAddClose = () => {
    setAddIsOpen(false);

    if (inputRef.current) {
      inputRef.current.value = "";
    }
  };

  const handleFileChange = async (files: FileList | null) => {
    if (!files || files.length < 1) {
      return;
    }

    const file = files[0];

    if (uploadDocumentId === 0) {
      setAddFile(file);
      setAddIsOpen(true);
      return;
    }

    try {
      await doUpload(file);
    } finally {
      if (inputRef.current) {
        inputRef.current.value = "";
      }
    }
  };

  const handleDownload = async (item: IClientDocumentItem) => {
    try {
      await promiseToast(
        async () => {
          const url = await clientApi.getClientDocumentDownloadUrl(
            clientId!,
            item.id
          );

          const link = document.createElement("a");
          link.href = url.data;
          link.download = item.name;
          link.target = "_blank";
          link.click();
        },
        t("pending.download"),
        t("success.download"),
        t("errors.unknown")
      );
    } catch {
      //Nothing.
    }
  };

  const handleUpload = async (item: IClientDocumentItem) => {
    setUploadDocumentId(item.id);
    if (inputRef.current) {
      inputRef.current.click();
    }
  };

  const doUpload = async (file: File) => {
    await promiseToastSave(async () => {
      const uploadUrl = await clientApi.getClientDocumentUploadUrl(
        clientId!,
        folderId,
        null,
        uploadDocumentId.toString()
      );

      await bucketApi.uploadDocument(uploadUrl.data, file);
      await clientApi.updateClientDocument(clientId!, uploadDocumentId);

      clientDocumentListLoad(true);
    });
  };

  const handleFromTemplate = () => {
    setTemplateIsOpen(true);
  };

  const handleCloseTemplate = () => {
    setTemplateIsOpen(false);
  };

  const handleConfirmTemplate = async (file: ISelectedFile) => {
    await promiseToastSave(async () => {
      await clientApi.addClientDocumentFromTemplate(
        clientId!,
        folderId,
        file.id
      );
      clientDocumentListLoad(true);
    });
  };

  const handleEditOnline = async (item: IClientDocumentItem) => {
    await startDocumentEditOnline(item.id, t);
  };

  const handleRenderData = (item: IClientDocumentItem): ReactElement => {
    return (
      <>
        <TableCol>{item.name}</TableCol>
        <TableCol>{format(item.createdAt, DATE_TIME_FORMAT)}</TableCol>
        <TableCol>{format(item.lastEditedAt, DATE_TIME_FORMAT)}</TableCol>
        <TableCol>{item.author.name}</TableCol>
        <TableCol>{item.lastEditor.name}</TableCol>
        <TableCol>
          {hasClientWriteRight(identityRights, [
            RightType.WriteClientDocuments,
          ]) && (
            <>
              <GridIconEdit onClick={() => handleEditOnline(item)} />
              <GridIconSpace />
            </>
          )}
          <GridIconDownload onClick={() => handleDownload(item)} />
          {hasClientWriteRight(identityRights, [
            RightType.WriteClientDocuments,
          ]) && (
            <>
              <GridIconSpace />
              <GridIconUpload onClick={() => handleUpload(item)} />
            </>
          )}
        </TableCol>
      </>
    );
  };

  const handleGetData = (cancelToken: CancelToken) =>
    getClientDocumentList(clientId!, folderId!, cancelToken);

  const handleGetCount = (cancelToken: CancelToken) =>
    getClientDocumentListCount(clientId!, folderId!, cancelToken);

  return (
    <>
      <input
        ref={inputRef}
        type="file"
        hidden
        onChange={({ target }) => handleFileChange(target.files)}
      />
      <ClientDocumentAddModal
        file={addFile}
        clientId={clientId!}
        isOpen={addIsOpen}
        close={handleAddClose}
      />
      <SelectTemplateModal
        type={TemplateDocumentType.Client}
        isOpen={templateIsOpen}
        close={handleCloseTemplate}
        confirm={handleConfirmTemplate}
      />

      <FilterPanel
        title={t("module.document.pageTitle")}
        name="clientDocument"
        filter={prov.filter!}
        changeFilter={changeClientDocumentListFilter}
        initialValues={{
          createdFrom: prov.filter!.createdFrom,
          createdTo: prov.filter!.createdTo,
          lastEditFrom: prov.filter!.lastEditFrom,
          lastEditTo: prov.filter!.lastEditTo,
          authorId: prov.filter!.authorId,
          authorName: prov.filter!.authorName,
          lastEditorId: prov.filter!.lastEditorId,
          lastEditorName: prov.filter!.lastEditorName,
        }}
      >
        <CalendarModalTimesContainer>
          <label>{t("document.createdAt")}</label>
          <CalendarModalTimes>
            <div>
              <CalendarModalTimesLabel>
                {t("common.from")}
              </CalendarModalTimesLabel>
              <DatePicker name={"createdFrom"} />
            </div>
            <div>
              <CalendarModalTimesLabel>
                {t("common.to")}
              </CalendarModalTimesLabel>
              <DatePicker name={"createdTo"} />
            </div>
          </CalendarModalTimes>
        </CalendarModalTimesContainer>
        <CalendarModalTimesContainer>
          <label>{t("document.lastEditedAt")}</label>
          <CalendarModalTimes>
            <div>
              <CalendarModalTimesLabel>
                {t("common.from")}
              </CalendarModalTimesLabel>
              <DatePicker name={"lastEditFrom"} />
            </div>
            <div>
              <CalendarModalTimesLabel>
                {t("common.to")}
              </CalendarModalTimesLabel>
              <DatePicker name={"lastEditTo"} />
            </div>
          </CalendarModalTimes>
        </CalendarModalTimesContainer>
        <SuggestionFormik
          nameId="authorId"
          nameText="authorName"
          label={t("document.author")}
          loadSuggestions={handleLoadUserSuggestions}
        />
        <SuggestionFormik
          nameId="lastEditorId"
          nameText="lastEditorName"
          label={t("document.lastEditor")}
          loadSuggestions={handleLoadUserSuggestions}
        />
      </FilterPanel>
      <Grid<IClientDocumentItem>
        headers={[
          {
            captionStr: "document.name",
            orderName: "Name",
          },
          {
            captionStr: "document.createdAt",
            orderName: "CreatedAt",
          },
          {
            captionStr: "document.lastEditedAt",
            orderName: "LastEditedAt",
          },
          {
            captionStr: "document.author",
            orderName: "Author",
          },
          {
            captionStr: "document.lastEditor",
            orderName: "LastEditor",
          },
          {
            captionEl: hasClientWriteRight(identityRights, [
              RightType.WriteClientDocuments,
            ]) ? (
              <DivTextRight>
                <GridIconAdd onClick={handleAdd} />
                {hasRight(identityRights, [RightType.ReadAllTemplates]) && (
                  <>
                    <GridIconSpace />
                    <GridIconFromTemplate onClick={handleFromTemplate} />
                  </>
                )}
              </DivTextRight>
            ) : undefined,
          },
        ]}
        renderData={handleRenderData}
        getData={handleGetData}
        getCount={handleGetCount}
        changeOrder={changeClientDocumentListOrder}
        changePage={changeClientDocumentListPage}
        prov={prov}
      />
    </>
  );
};

const mapStateToProps = (state: IApplicationState) => {
  return {
    prov: selectClientDocumentList(state),
    clientId: selectClientId(state),
    identityRights: selectIdentityRights(state),
  };
};

const mapDispachToProps = {
  getClientDocumentList,
  getClientDocumentListCount,
  changeClientDocumentListOrder,
  changeClientDocumentListPage,
  changeClientDocumentListFilter,
  clientDocumentListLoad,
};

export default connect(
  mapStateToProps,
  mapDispachToProps
)(ClientDocumentDocument);
