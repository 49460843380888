import { Form, Formik } from "formik";
import { FC, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { connect } from "react-redux";
import { useNavigate, useParams } from "react-router";
import { IAssistanceAddress } from "../../models/assistance";
import { IApplicationState } from "../../store";
import { StoreState } from "../../store/storeState";
import {
  getDefaultAssistanceAddress,
  getAssistanceAddress,
  selectAssistanceAddress,
  selectAssistanceAddressState,
} from "../../store/assistanceAddress";
import { FormButton } from "../../styles/button";
import { ApiError, FormGroup } from "../../styles/form";
import Input from "../common/form/Input";
import PhoneInput from "../common/form/PhoneInput";
import Loader from "../common/Loader";
import * as Yup from "yup";
import { assistanceAddressListLoad } from "../../store/assistanceAddressList";
import assistanceApi from "../../api/assistance";
import { SpaceBetweenButtons } from "../../styles/spaces";
import validations from "../../utils/validations";
import { selectAssistanceId } from "../../store/assistance";
import { RightType } from "../../models/auth";
import { hasRight } from "../../utils/rights";
import { selectIdentityRights } from "../../store/identity";
import { promiseToastSave } from "../../utils/toasts";
import SubmitForm from "../common/form/SubmitForm";
import { errorSet } from "../../utils/error";
import SuggestionListFormik from "../common/suggestion/SuggestionListFormik";
import {
  handleLoadCareTypeSuggestions,
  handleLoadTargetGroupListingSuggestions,
} from "../../utils/suggestions";

interface IAssistanceAddressForm
  extends Omit<
    IAssistanceAddress,
    "houseNumber" | "landRegistryNumber" | "postCode" | "latitude" | "longitude"
  > {
  houseNumber: string;
  landRegistryNumber: string;
  postCode: string;
  latitude: string;
  longitude: string;
}

interface IProps {
  assistanceId?: number;
  assistanceAddressState: StoreState;
  assistanceAddress: IAssistanceAddress | null;
  identityRights?: RightType[];
  getAssistanceAddress(assistanceAddressId: number): void;
  getDefaultAssistanceAddress(): void;
  assistanceAddressListLoad(reload: boolean): void;
}

const addressFields: Array<{
  name: keyof IAssistanceAddress;
  maxLen?: number;
}> = [
  { name: "name", maxLen: 100 },
  { name: "street", maxLen: 150 },
  { name: "houseNumber" },
  { name: "landRegistryNumber" },
  { name: "city", maxLen: 150 },
  { name: "postCode" },
];

const coordinatesFileds: Array<{
  name: keyof IAssistanceAddress;
  maxLen?: number;
}> = [{ name: "latitude" }, { name: "longitude" }];

const openingHoursFields: Array<{
  name: keyof IAssistanceAddress;
  maxLen?: number;
}> = [
  { name: "openMonday", maxLen: 100 },
  { name: "openTuesday", maxLen: 100 },
  { name: "openWednesday", maxLen: 100 },
  { name: "openThursday", maxLen: 100 },
  { name: "openFriday", maxLen: 100 },
  { name: "openSaturday", maxLen: 100 },
  { name: "openSunday", maxLen: 100 },
];

const numberFields: Array<keyof IAssistanceAddress> = [
  "houseNumber",
  "landRegistryNumber",
  "postCode",
  "latitude",
  "longitude",
];

const AssistanceAddressDetail: FC<IProps> = ({
  assistanceId,
  assistanceAddressState,
  assistanceAddress,
  identityRights,
  getAssistanceAddress,
  getDefaultAssistanceAddress,
  assistanceAddressListLoad,
}) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { assistanceId: paramAssistanceId, addressId } = useParams();
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);

  useEffect(() => {
    if (addressId === "new") {
      getDefaultAssistanceAddress();
    } else {
      getAssistanceAddress(parseInt(addressId!));
    }
    setLoading(false);
  }, [addressId, getAssistanceAddress, getDefaultAssistanceAddress]);

  const handleSubmit = async (data: IAssistanceAddressForm) => {
    setError(null);
    try {
      await promiseToastSave(async () => {
        const data2 = {
          ...data,
          houseNumber: +data.houseNumber,
          landRegistryNumber: +data.landRegistryNumber,
          postCode: +data.postCode,
          latitude: +data.latitude,
          longitude: +data.longitude,
        };

        if (addressId === "new") {
          await assistanceApi.createAddress(assistanceId!, data2);
        } else {
          await assistanceApi.updateAddress(data2);
        }

        assistanceAddressListLoad(true);
        navigate(`/assistance/${paramAssistanceId}/address`);
      });
    } catch (err) {
      errorSet(setError, err, t);
    }
  };

  const handleCancel = () => {
    navigate(`/assistance/${paramAssistanceId}/address`);
  };

  if (loading || assistanceAddressState === StoreState.Loading) {
    return <Loader />;
  }

  if (assistanceAddressState === StoreState.Error) {
    return t("errors.unknown");
  }

  return (
    <Formik<IAssistanceAddressForm>
      initialValues={{
        ...assistanceAddress!,
        mobilePhone: assistanceAddress!.mobilePhone ?? "",
        email: assistanceAddress!.email ?? "",
        webLink: assistanceAddress!.webLink ?? "",
        phone: assistanceAddress!.phone ?? "",
        houseNumber: assistanceAddress!.houseNumber
          ? assistanceAddress!.houseNumber.toString()
          : "",
        landRegistryNumber: assistanceAddress!.landRegistryNumber
          ? assistanceAddress!.landRegistryNumber.toString()
          : "",
        postCode: assistanceAddress!.postCode
          ? assistanceAddress!.postCode.toString()
          : "",
        latitude: assistanceAddress!.latitude
          ? assistanceAddress!.latitude.toString()
          : "",
        longitude: assistanceAddress!.longitude
          ? assistanceAddress!.longitude.toString()
          : "",
      }}
      validationSchema={Yup.object({
        name: validations.stringRequired(t),
        street: validations.stringRequired(t),
        houseNumber: validations.intRequiredMinMax(1, 999999, t),
        landRegistryNumber: validations.intOptionalMinMax(1, 999999, t),
        city: validations.stringRequired(t),
        postCode: validations.postCodeRequired(t),
        mobilePhone: validations.phoneOptional(t),
        email: validations.emailOptional(t),
        webLink: validations.urlOptional(t),
        phone: validations.phoneOptional(t),
        latitude: validations.numberMoreThanZero(t),
        longitude: validations.numberMoreThanZero(t),
        openMonday: validations.stringRequired(t),
        openTuesday: validations.stringRequired(t),
        openWednesday: validations.stringRequired(t),
        openThursday: validations.stringRequired(t),
        openFriday: validations.stringRequired(t),
        openSaturday: validations.stringRequired(t),
        openSunday: validations.stringRequired(t),
      })}
      validateOnMount={true}
      onSubmit={handleSubmit}
    >
      {({ errors, touched, isSubmitting }) => (
        <Form>
          <h1>{t("assistance.address.detailTitle")}</h1>
          <FormGroup>
            {addressFields.map((f) => (
              <Input
                key={f.name}
                name={f.name}
                type={
                  numberFields.find((x) => x === f.name) ? "number" : "text"
                }
                label={t("assistance.address." + f.name)}
                error={touched[f.name] && !!errors[f.name]}
                maxLength={f.maxLen}
              />
            ))}
            <PhoneInput
              name="mobilePhone"
              label={t("assistance.address.mobilePhone")}
              error={touched.mobilePhone && !!errors.mobilePhone}
            />
            <Input
              name="email"
              label={t("assistance.address.email")}
              error={touched.email && !!errors.email}
              maxLength={255}
            />
            <Input
              name="webLink"
              label={t("assistance.address.webLink")}
              error={touched.webLink && !!errors.webLink}
              maxLength={256}
            />
            <PhoneInput
              name="phone"
              label={t("assistance.address.phone")}
              error={touched.phone && !!errors.phone}
            />
            {coordinatesFileds.map((f) => (
              <Input
                key={f.name}
                name={f.name}
                type={
                  numberFields.find((x) => x === f.name) ? "number" : "text"
                }
                label={t("assistance.address." + f.name)}
                error={touched[f.name] && !!errors[f.name]}
                maxLength={f.maxLen}
              />
            ))}
            <SuggestionListFormik
              nameList="careTypeList"
              nameId="id"
              nameText="name"
              label={t("assistance.address.careTypeList")}
              loadSuggestions={handleLoadCareTypeSuggestions}
            />
            <SuggestionListFormik
              nameList="targetGroupList"
              nameId="id"
              nameText="name"
              label={t("assistance.address.targetGroupList")}
              loadSuggestions={handleLoadTargetGroupListingSuggestions}
            />

            <hr />

            <h2>{t("assistance.address.openingHours")}</h2>
            {openingHoursFields.map((f) => (
              <Input
                key={f.name}
                name={f.name}
                type={
                  numberFields.find((x) => x === f.name) ? "number" : "text"
                }
                label={t("assistance.address." + f.name)}
                error={touched[f.name] && !!errors[f.name]}
                maxLength={f.maxLen}
              />
            ))}
          </FormGroup>
          {error && <ApiError>{error}</ApiError>}
          <FormButton
            ver="secondary"
            disabled={isSubmitting}
            onClick={handleCancel}
          >
            {t("common.back")}
          </FormButton>
          {hasRight(identityRights, [RightType.WriteAssistances]) && (
            <>
              <SpaceBetweenButtons />
              <SubmitForm />
            </>
          )}
        </Form>
      )}
    </Formik>
  );
};

const mapStateToProps = (state: IApplicationState) => {
  return {
    assistanceId: selectAssistanceId(state),
    assistanceAddressState: selectAssistanceAddressState(state),
    assistanceAddress: selectAssistanceAddress(state),
    identityRights: selectIdentityRights(state),
  };
};

const mapDispachToProps = {
  getAssistanceAddress,
  getDefaultAssistanceAddress,
  assistanceAddressListLoad,
};

export default connect(
  mapStateToProps,
  mapDispachToProps
)(AssistanceAddressDetail);
