import { NavLink } from "react-router-dom";
import styled from "styled-components";
import { SIZES } from "../../styles/sizes";

// todo colors
export const TopPanel = styled.div<{ color: string }>`
  color: white;
  width: 100%;
  min-height: 4rem;
  display: flex;
  align-items: center;
  padding-left: 2rem;
  background-color: #1f2b40;
  border-bottom: 5px solid;
  border-color: ${({ color }) => color};
  font-size: 1.4rem;

  @media screen and (max-width: ${SIZES.tablet}) {
    font-size: 1.2rem;
  }
  @media screen and (max-width: ${SIZES.mobile}) {
    font-size: 1rem;
  }
`;

export const TopPanelBack = styled(NavLink)`
  margin-right: 2rem;
`;

export const TopPanelCenterContainer = styled.div`
  display: flex;
  flex-direction: column;

  @media screen and (min-width: ${SIZES.laptop}) {
    flex-direction: row;
    align-items: center;
  }
`;

export const TopPanelRouterContainer = styled.div`
  @media screen and (min-width: ${SIZES.laptop}) {
    width: 28rem;
  }
`;

export const TopPanelNameContainer = styled.div`
  margin-left: 0;
  font-weight: bold;

  @media screen and (min-width: ${SIZES.laptop}) {
    margin-left: 1rem;
  }
`;

export const TopPanelStateContainer = styled.div`
  display: flex;
  gap: 0.5rem;
  margin: 0 2rem 0 auto;
  padding-left: 2rem;
  font-size: 1.4rem;
  svg {
    width: 1.4rem;
  }
`;

export const TopPanelStateLabel = styled.span`
  @media screen and (max-width: ${SIZES.tablet}) {
    display: none;
  }
`;

export const TopPanelState = styled.span<{ color?: string }>`
  color: ${({ color }) => color};
  display: flex;
  gap: 0.3rem;
  align-items: center;
  text-transform: uppercase;
  font-weight: bold;
`;
