import { AppThunkAction, AppThunkActionAsync } from "..";
import * as actions from "./actions";
import clientApi from "../../api/client";
import { IClientListFilter, IClientListState, selectClientList } from ".";
import { CancelToken } from "axios";
import {
  listEffectCountFilter,
  listEffectGetFilter,
  listEffectLoad,
  listEffectReload,
  listEffectsCountCheck,
  listEffectsGetCheck,
  listEffectsGetCount,
  listEffectsGetNew,
  listEffectsGetParams,
} from "../listEffect";
import { ClientStateType } from "../../models/client";

export function getClientList(cancelToken: CancelToken): AppThunkActionAsync {
  return async (dispatch, getState) => {
    const state = selectClientList(getState());
    if (listEffectsGetCheck(state.state)) {
      return;
    }

    let newState = listEffectsGetNew(state);
    dispatch(actions.clientListFetchRequest(newState.newCountState));
    try {
      const params = listEffectsGetParams(state, newState.take);
      listEffectGetFilter(params, state.filter);
      addClientParams(params, state);

      const clients = await clientApi.getList({ params, cancelToken });
      cancelToken.throwIfRequested();
      newState = listEffectsGetCount(newState, clients);

      dispatch(
        actions.clientListFetchSuccess({
          data: clients.data,
          newCountState: newState.newCountState,
          newCount: newState.newCount,
        })
      );
    } catch {
      dispatch(actions.clientListFetchError(cancelToken.reason !== undefined));
    }
  };
}

export function getClientListCount(
  cancelToken: CancelToken
): AppThunkActionAsync {
  return async (dispatch, getState) => {
    const state = selectClientList(getState());
    if (listEffectsCountCheck(state.countState)) {
      return;
    }

    dispatch(actions.clientListCountRequest());
    try {
      const params = listEffectCountFilter(state.filter);
      addClientParams(params, state);

      const count = await clientApi.getCount({ params, cancelToken });
      cancelToken.throwIfRequested();

      dispatch(actions.clientListCountSuccess(count.data));
    } catch {
      dispatch(actions.clientListCountError(cancelToken.reason !== undefined));
    }
  };
}

export function changeClientListOrder(
  orderBy: string,
  orderDesc: boolean
): AppThunkAction {
  return async (dispatch) => {
    dispatch(actions.clientListChangeOrder({ orderBy, orderDesc }));
  };
}

export function changeClientListPage(page: number): AppThunkAction {
  return async (dispatch) => {
    dispatch(actions.clientListChangePage(page));
  };
}

export function changeClientListFilter(
  filter: IClientListFilter
): AppThunkAction {
  return async (dispatch) => {
    dispatch(actions.clientListChangeFilter(filter));
  };
}

export function openClientListFilter(): AppThunkAction {
  return async (dispatch, getState) => {
    const state = getState();
    dispatch(
      actions.clientListChangeFilter({
        ...state.clientList.filter!,
        isOpen: true,
      })
    );
  };
}

export function clientListLoad(reload: boolean): AppThunkAction {
  return async (dispatch, getState) => {
    const state = selectClientList(getState());
    reload = listEffectReload(reload, state);
    if (reload) {
      dispatch(actions.clientListReset());
    } else {
      const newState = listEffectLoad(state);
      dispatch(
        actions.clientListLoadAction({
          newState: newState.newState,
          newCountState: newState.newCountState,
        })
      );
    }
  };
}

export function addClientParams(
  params: URLSearchParams,
  state: IClientListState
) {
  if (state.filter!.isStateBasic) {
    params.append("States", ClientStateType.Basic);
  }
  if (state.filter!.isStateNew) {
    params.append("States", ClientStateType.New);
  }
  if (state.filter!.isStateActive) {
    params.append("States", ClientStateType.Active);
  }
  if (state.filter!.isStateInActive) {
    params.append("States", ClientStateType.InActive);
  }
  if (state.filter!.isStateRejected) {
    params.append("States", ClientStateType.Rejected);
  }
  if (state.filter!.isStateDead) {
    params.append("States", ClientStateType.Dead);
  }
  if (state.filter!.isStateAnonymised) {
    params.append("States", ClientStateType.Anonymised);
  }

  if (state.filter!.keyCaregiverId) {
    params.append("KeyCaregiverId", state.filter!.keyCaregiverId.toString());
  }
  if (state.filter!.assistanceId) {
    params.append("AssistanceId", state.filter!.assistanceId.toString());
  }
}
