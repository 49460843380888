import { addDays } from "date-fns";

export function getClassByDate(date: Date): string {
  const now = new Date();

  if (date < now) {
    return "error";
  }

  if (date < addDays(now, 3)) {
    return "warning";
  }

  return "";
}
