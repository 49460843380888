import { AxiosResponse, CancelToken } from "axios";
import assistanceApi from "../api/assistance";
import careTypeApi from "../api/careType";
import clientApi from "../api/client";
import groupApi from "../api/group";
import healthInsuranceApi from "../api/healthInsurance";
import listingApi from "../api/listing";
import notificationApi from "../api/notification";
import roleApi from "../api/role";
import userApi from "../api/user";
import workflowApi from "../api/workflow";
import workflowTemplateApi from "../api/workflowTemplate";
import { ISuggestionValue } from "../components/common/suggestion/Suggestion";
import { IItemIdName } from "../models/common";
import { ListingType } from "../models/listing";
import { SUGGESTION_SIZE } from "./consts";

const getSuggestionParams = (search?: string | null) => {
  const params = new URLSearchParams();
  params.append("count", SUGGESTION_SIZE.toString());

  if (search) {
    params.append("search", search);
  }

  return params;
};

const responseMap = (response: AxiosResponse<IItemIdName[]>) => {
  return response.data.map((x) => ({
    id: x.id,
    text: x.name,
  }));
};

export const handleLoadUserSuggestions = async (
  text: string,
  cancelToken: CancelToken
): Promise<ISuggestionValue[]> => {
  const params = getSuggestionParams(text);
  const response = await userApi.getSuggestionList({ params, cancelToken });
  return responseMap(response);
};

export const handleLoadRoleSuggestions = async (
  text: string,
  cancelToken: CancelToken
): Promise<ISuggestionValue[]> => {
  const params = getSuggestionParams(text);
  const response = await roleApi.getSuggestionList({ params, cancelToken });
  return responseMap(response);
};

export const handleLoadAssistanceSuggestions = async (
  text: string,
  cancelToken: CancelToken,
  careTypeId?: number | null
): Promise<ISuggestionValue[]> => {
  const params = getSuggestionParams(text);

  if (careTypeId) {
    params.append("careTypeId", careTypeId.toString());
  }

  const response = await assistanceApi.getSuggestionList({
    params,
    cancelToken,
  });
  return responseMap(response);
};

export const handleLoadAssistanceAddressSuggestions = async (
  assistanceId: number,
  text: string,
  cancelToken: CancelToken,
  careTypeId?: number | null
): Promise<ISuggestionValue[]> => {
  const params = getSuggestionParams(text);

  if (careTypeId) {
    params.append("careTypeId", careTypeId.toString());
  }

  const response = await assistanceApi.getAddressSuggestionList(assistanceId, {
    params,
    cancelToken,
  });
  return responseMap(response);
};

export const handleLoadGroupSuggestions = async (
  text: string,
  cancelToken: CancelToken
): Promise<ISuggestionValue[]> => {
  const params = getSuggestionParams(text);
  const response = await groupApi.getSuggestionList({ params, cancelToken });
  return responseMap(response);
};

export const handleLoadNotificationSuggestions = async (
  text: string,
  cancelToken: CancelToken
): Promise<ISuggestionValue[]> => {
  const params = getSuggestionParams(text);
  const response = await notificationApi.getSuggestionList({
    params,
    cancelToken,
  });
  return responseMap(response);
};

export const handleLoadClientSuggestions = async (
  text: string,
  cancelToken: CancelToken
): Promise<ISuggestionValue[]> => {
  const params = getSuggestionParams(text);
  const response = await clientApi.getSuggestionList({ params, cancelToken });
  return responseMap(response);
};

export const handleLoadWorkflowTemplateSuggestions = async (
  text: string,
  cancelToken: CancelToken
): Promise<ISuggestionValue[]> => {
  const params = getSuggestionParams(text);
  const response = await workflowTemplateApi.getSuggestionList({
    params,
    cancelToken,
  });
  return responseMap(response);
};

export const handleLoadWorkflowSuggestions = async (
  text: string,
  cancelToken: CancelToken
): Promise<ISuggestionValue[]> => {
  const params = getSuggestionParams(text);
  const response = await workflowApi.getSuggestionList({ params, cancelToken });
  return responseMap(response);
};

const handleLoadListingSuggestions = async (
  type: ListingType,
  text: string,
  cancelToken: CancelToken
): Promise<ISuggestionValue[]> => {
  const params = getSuggestionParams(text);
  const response = await listingApi.getSuggestionList(type, {
    params,
    cancelToken,
  });
  return responseMap(response);
};

export const handleLoadActionListingSuggestions = async (
  text: string,
  cancelToken: CancelToken
): Promise<ISuggestionValue[]> => {
  return await handleLoadListingSuggestions(
    ListingType.ActionListing,
    text,
    cancelToken
  );
};

export const handleLoadAssistanceCategoryListingSuggestions = async (
  text: string,
  cancelToken: CancelToken
): Promise<ISuggestionValue[]> => {
  return await handleLoadListingSuggestions(
    ListingType.AssistanceCategoryListing,
    text,
    cancelToken
  );
};

export const handleLoadTargetGroupListingSuggestions = async (
  text: string,
  cancelToken: CancelToken
): Promise<ISuggestionValue[]> => {
  return await handleLoadListingSuggestions(
    ListingType.TargetGroupListing,
    text,
    cancelToken
  );
};

export const handleLoadContactPersonListingSuggestions = async (
  text: string,
  cancelToken: CancelToken
): Promise<ISuggestionValue[]> => {
  return await handleLoadListingSuggestions(
    ListingType.ContactPersonListing,
    text,
    cancelToken
  );
};

export const handleLoadClientContactPersonListingSuggestions = async (
  text: string,
  cancelToken: CancelToken
): Promise<ISuggestionValue[]> => {
  return await handleLoadListingSuggestions(
    ListingType.ClientContactPersonListing,
    text,
    cancelToken
  );
};

export const handleLoadClientRiskListingSuggestions = async (
  text: string,
  cancelToken: CancelToken
): Promise<ISuggestionValue[]> => {
  return await handleLoadListingSuggestions(
    ListingType.ClientRiskListing,
    text,
    cancelToken
  );
};

export const handleLoadSocialBenefitListingSuggestions = async (
  text: string,
  cancelToken: CancelToken
): Promise<ISuggestionValue[]> => {
  return await handleLoadListingSuggestions(
    ListingType.SocialBenefitListing,
    text,
    cancelToken
  );
};

export const handleLoadWorkflowCategoryListingSuggestions = async (
  text: string,
  cancelToken: CancelToken
): Promise<ISuggestionValue[]> => {
  return await handleLoadListingSuggestions(
    ListingType.WorkflowCategoryListing,
    text,
    cancelToken
  );
};

export const handleLoadHealthInsuranceSuggestions = async (
  text: string,
  cancelToken: CancelToken
): Promise<ISuggestionValue[]> => {
  const params = getSuggestionParams(text);
  const response = await healthInsuranceApi.getSuggestionList({
    params,
    cancelToken,
  });
  return responseMap(response);
};

export const handleLoadCareTypeSuggestions = async (
  text: string,
  cancelToken: CancelToken,
  assistanceCategoryId?: number | null
): Promise<ISuggestionValue[]> => {
  const params = getSuggestionParams(text);

  if (assistanceCategoryId) {
    params.append("assistanceCategoryId", assistanceCategoryId.toString());
  }

  const response = await careTypeApi.getSuggestionList({
    params,
    cancelToken,
  });
  return responseMap(response);
};
