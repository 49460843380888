import { IClientItem } from "../../models/client";
import { IFilterType } from "../filterType";
import { IListType } from "../listType";

export enum ClientListTypes {
  CLIENT_LIST_FETCH_REQUEST = "@@clientList/FETCH_REQUEST",
  CLIENT_LIST_FETCH_SUCCESS = "@@clientList/FETCH_SUCCESS",
  CLIENT_LIST_FETCH_ERROR = "@@clientList/FETCH_ERROR",
  CLIENT_LIST_COUNT_REQUEST = "@@clientList/COUNT_REQUEST",
  CLIENT_LIST_COUNT_SUCCESS = "@@clientList/COUNT_SUCCESS",
  CLIENT_LIST_COUNT_ERROR = "@@clientList/COUNT_ERROR",
  CLIENT_LIST_CHANGE_ORDER = "@@clientList/CHANGE_ORDER",
  CLIENT_LIST_CHANGE_PAGE = "@@clientList/CHANGE_PAGE",
  CLIENT_LIST_CHANGE_FILTER = "@@clientList/CHANGE_FILTER",
  CLIENT_LIST_RESET = "@@clientList/RESET",
  CLIENT_LIST_LOAD = "@@clientList/LOAD",
}

export interface IClientListFilter extends IFilterType {
  isStateBasic: boolean;
  isStateNew: boolean;
  isStateActive: boolean;
  isStateInActive: boolean;
  isStateRejected: boolean;
  isStateDead: boolean;
  isStateAnonymised: boolean;

  keyCaregiverId?: number;
  keyCaregiverName?: string;

  assistanceId?: number;
  assistanceName?: string;
}

export interface IClientListState
  extends IListType<IClientItem, IClientListFilter> {}
